import { localData } from "../../services/localData/implementations/indexeddb/BainDatabase";
import { rgLog } from "../../services/log";
import type { Portal } from "../../models/portal";

export const getUserLanguageForPortal = async function (tenantId: string, portalName: string) {
	let userLanguageForPortal;
	try {
		userLanguageForPortal = await localData.languages.get({
			customerKey: tenantId.toLowerCase(),
			key: portalName.toLowerCase(),
		});
	} catch (e) {
		rgLog("send", e);
	}
	return userLanguageForPortal?.language;
};

export const getDefaultPortalLanguage = function (portal: Portal) {
	if (portal.defaultPortalLanguage) {
		return portal.defaultPortalLanguage;
	}
	const languageDecimal = portal.userLanguages.find((language) => language.isDefault)?.idDecimal;
	return languageDecimal ? mapLanguageDecimalIdToStringId(languageDecimal) : "en-gb";
};

export const storeLanguage = function (language: string, tenantId: string, portalName: string) {
	return localData.languages.put({
		customerKey: tenantId.toLowerCase(),
		key: portalName.toLowerCase(),
		language,
	});
};

/**
 * Map decimal locale id to string locale id
 */
export const mapLanguageDecimalIdToStringId = function (idDecimal: number): string {
	switch (idDecimal) {
		case 14337:
			return "ar-ae";
		case 1041:
			return "ja-jp";
		case 1086:
			return "ms-my";
		case 73:
			return "ta";
		case 1066:
			return "vi-vn";
		case 1049:
			return "ru-ru";
		case 1042:
			return "ko-kr";
		case 1081:
			return "hi-in";
		case 1035:
			return "fi-fi";
		case 3084:
			return "fr-ca";
		case 1044:
			return "nb-no";
		case 1040:
			return "it-it";
		case 3082:
			return "es-es";
		case 1031:
			return "de-de";
		case 1043:
			return "nl-nl";
		case 1036:
			return "fr-fr";
		case 1076: // This is the testing language code with a fake number
			return "pe-pe";
		case 1033:
			return "en-us";
		case 21514:
			return "es-us";
		case 1094:
			return "pa-in";
		case 1063:
			return "lt-lt";
		case 1048:
			return "ro-ro";
		case 1045:
			return "pl-pl";
		case 1050:
			return "hr-hr";
		case 1051:
			return "sk-sk";
		case 1029:
			return "cs-cz";
		case 2070:
			return "pt-pt";
		case 1046:
			return "pt-br";
		case 1060:
			return "sl-si";
		case 1038:
			return "hu-hu";
		case 4:
			return "zh-hans";
		case 1030:
			return "da-dk";
		case 1053:
			return "sv-se";
		case 2057:
		default:
			return "en-gb";
	}
};
