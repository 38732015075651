import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
	createContext,
} from "react";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { ToastContext } from "../toast";
import { Loading } from "../Loading";
import { isIos13orMore, isIosSafari, isIosStandalone } from "../../../helpers/SafariHelper";
import { fileExtensionFromContentType } from "../../utilities/FileExtensionFromContentType";
import { rgLog } from "../../../services/log";
import {
	DownloadCompleteToast,
	DownloadErrorToast,
	DownloadInProgressToast,
} from "./DownloadToasts";
import { DownloadState } from "./Download";
import { DownloadModal } from "./DownloadModal";
import type { Download } from "./Download";

export const DownloadManagerContext = createContext({
	addDownload: (_download: Download) => {},
	retryDownload: (_download: Download) => {},
	deleteDownload: (_download: Download) => {},
	downloads: [] as Download[],
});

type Props = {
	children: React.ReactNode;
};

export const DownloadManagerProvider = ({ children }: Props) => {
	const [downloads, setDownloads] = useState<Download[]>([]);
	const [downloadingToastToken, setDownloadingToastToken] = useState<string>();
	const prevDownloadingCount = useRef(0);
	const [errorToastToken, setErrorToastToken] = useState<string>();
	const prevErrorCount = useRef(0);
	const toastContext = useContext(ToastContext);
	const [showDownloadsInProgressModal, setShowDownloadsInProgressModal] = useState(false);
	const [showDownloadsErrorsModal, setShowDownloadsErrorsModal] = useState(false);
	const { t } = useTranslation();

	const blockUi = useMemo(
		() =>
			!!(
				isIosSafari() &&
				downloads.filter((d) => d.status === DownloadState.InProgress).length
			),
		[downloads],
	);

	const addDownload = useCallback((download: Download) => {
		download
			.downloadPromise()
			.then((r) => {
				saveAs(
					// if iOS and run in browser and iOS 13 or more: fake mime type so browser doesn't switch page to display file
					isIosSafari() && !isIosStandalone() && isIos13orMore()
						? new Blob([r], { type: "application/octet-stream" })
						: r,
					download.addExtension
						? download.fileName + fileExtensionFromContentType(r.type)
						: download.fileName,
				);
				setDownloads((ds) => ds.filter((d) => d !== download));
			})
			.catch((e) => {
				rgLog("send", e);
				download.status = DownloadState.Error;
				setDownloads((ds) => [...ds]);
			});
		setDownloads((ds) => ds.concat([download]));
	}, []);

	const retryDownload = useCallback(
		(download: Download) => {
			setDownloads((ds) => ds.filter((d) => d !== download));
			download.status = DownloadState.InProgress;
			addDownload(download);
		},
		[addDownload],
	);

	const deleteDownload = useCallback((download: Download) => {
		if (download.status === DownloadState.Error) {
			setDownloads((ds) => ds.filter((d) => d !== download));
		}
	}, []);

	// Display downloading and download complete notifications
	useEffect(() => {
		const downloadingCount = downloads.filter(
			(d) => d.status === DownloadState.InProgress,
		).length;
		if (prevDownloadingCount.current !== downloadingCount) {
			if (downloadingCount === 0) {
				downloadingToastToken && toastContext.removeToast(downloadingToastToken);
				if (!downloads.length) {
					let uuid = "";
					const onCloseToast = () => toastContext.removeToast(uuid);
					uuid = toastContext.putToast({
						content: <DownloadCompleteToast onClick={onCloseToast} />,
						timeout: 3000,
					});
				}
				setDownloadingToastToken(undefined);
			} else if (downloadingCount > 0 && !blockUi) {
				const token = toastContext.putToast({
					content: (
						<DownloadInProgressToast
							amount={downloadingCount}
							onClick={() => setShowDownloadsInProgressModal(true)}
						/>
					),
					uuid: downloadingToastToken,
				});
				if (!downloadingToastToken) {
					setDownloadingToastToken(token);
				}
			}
			prevDownloadingCount.current = downloadingCount;
		}
	}, [toastContext, downloadingToastToken, setDownloadingToastToken, downloads, blockUi]);

	// Display error notifications
	useEffect(() => {
		const errorCount = downloads.filter((d) => d.status === DownloadState.Error).length;
		if (prevErrorCount.current !== errorCount) {
			if (errorCount === 0 && errorToastToken) {
				toastContext.removeToast(errorToastToken);
				setErrorToastToken(undefined);
			} else {
				const token = toastContext.putToast({
					content: (
						<DownloadErrorToast
							amount={errorCount}
							onClick={() => setShowDownloadsErrorsModal(true)}
						/>
					),
					uuid: errorToastToken,
				});
				if (!errorToastToken) {
					setErrorToastToken(token);
				}
			}
			prevErrorCount.current = errorCount;
		}
	}, [toastContext, errorToastToken, setErrorToastToken, downloads]);

	return (
		<DownloadManagerContext.Provider
			value={{ addDownload, retryDownload, deleteDownload, downloads }}
		>
			{children}
			<DownloadModal
				onCancel={() => {
					setShowDownloadsInProgressModal(false);
				}}
				show={showDownloadsInProgressModal}
				type={DownloadState.InProgress}
			/>
			<DownloadModal
				onCancel={() => {
					setShowDownloadsErrorsModal(false);
				}}
				show={showDownloadsErrorsModal}
				type={DownloadState.Error}
			/>
			{blockUi && <Loading text={t("display:labelDownloading")} withHistory={false} />}
		</DownloadManagerContext.Provider>
	);
};

export const DownloadManagerConsumer = DownloadManagerContext.Consumer;
