import { Suspense, useEffect, useState, useMemo } from "react";
import ReactGA from "react-ga";
import { createPortal } from "react-dom";
import { Switch, Route, BrowserRouter, Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import axiosInterceptors from "../services/utilities/interceptors";
import { extractCurrentPortalAuthenticationMode, isHiddenOnPortal } from "../helpers/PortalHelpers";
import { isBrowserIE } from "../helpers/ieHelper";
import { PortalAuthenticationMode } from "../models/portal";
import { rgLog } from "../services/log";
import { Authentication } from "./components/Authentication";
import { DownloadManagerProvider } from "./components/DownloadManagerContext/DownloadManagerContext";
import { Error } from "./components/error/Error";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { FeaturesPage } from "./pages/Features";
import { HomePage } from "./pages/HomePage";
import { AuditPage } from "./pages/AuditPage";
import { Loading } from "./components/Loading";
import { QuestionnairePage } from "./pages/QuestionnairePage";
import { ScrollRootToTop } from "./components/ScrollRootToTop";
import { UnsupportedBrowser } from "./components/UnsupportedBrowser";
import { NewAppId } from "./components/NewAppId";
import type { State } from "../state";
import type { HistoryLocationState } from "../types/History";
import type { RouteComponentProps } from "react-router-dom";
import "./styles/index.scss";

const RootInner = () => {
	const [currentUrl, setCurrentUrl] = useState("");
	const history = useHistory();

	useEffect(() => {
		const unregisterCallback = history.listen(() => setCurrentUrl(window.location.href));

		return () => unregisterCallback();
	}, []);

	const gaPageView = (url: string) => {
		if (url) {
			ReactGA.pageview(url);
		}

		rgLog("trackEvent", {
			type: "pageView",
			path: url, // Or perhaps window.location.hash
		});
	};

	const dispatch = useDispatch();

	useMemo(() => gaPageView(currentUrl), [currentUrl]);
	useEffect(() => {
		ReactGA.initialize("UA-515454-5");
		setCurrentUrl(window.location.href);
		axiosInterceptors(dispatch);
	}, [dispatch]);

	const isIE = useMemo(() => isBrowserIE(), []);

	const { t } = useTranslation();

	const authenticationMode = useSelector<State, PortalAuthenticationMode | null>((state) =>
		extractCurrentPortalAuthenticationMode(state),
	);

	return (
		<DownloadManagerProvider>
			<Route
				render={(props: RouteComponentProps) => (
					<ScrollRootToTop
						excludeRoute="/:customerKey/p/:portalKey/actions*"
						observe={props.location.pathname}
						scroll={!(props.location.state as HistoryLocationState)?.highlightRecord}
					/>
				)}
			/>

			<Switch>
				<Redirect
					exact
					from="/:customerKey/p/:portalKey/r/:questionnaireRecordId"
					to="/:customerKey/p/:portalKey/drafts/:questionnaireRecordId"
				/>

				<Redirect
					exact
					from="/:customerKey/p/:portalKey/f/:questionnaireName"
					to="/:customerKey/p/:portalKey/forms/:questionnaireName"
				/>

				{isIE && (
					<Redirect
						exact
						from="/:customerKey/p/:portalKey/:formsTab(drafts|queue)"
						to="/:customerKey/p/:portalKey"
					/>
				)}

				{authenticationMode !== null &&
					!isHiddenOnPortal(authenticationMode, [
						PortalAuthenticationMode.Private,
						PortalAuthenticationMode.Hybrid,
					]) && (
						<Redirect
							exact
							from="/:customerKey/p/:portalKey/actions*"
							to="/:customerKey/p/:portalKey"
						/>
					)}

				<Route exact path="/:customerKey/p/:portalKey/internal/flags">
					<FeaturesPage />
				</Route>

				<Route
					exact
					path={[
						"/:customerKey/p/:portalKey/:formsTab(forms)/:questionnaireName(\\d+)",
						"/:customerKey/p/:portalKey/:formsTab(drafts)/:questionnaireRecordId([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})",
						"/:customerKey/p/:portalKey/:formsTab(queue)/:questionnaireRecordId([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})",
					]}
				>
					<QuestionnairePage />
				</Route>

				<Route
					exact
					path={[
						"/:customerKey/p/:portalKey/settings",
						"/:customerKey/p/:portalKey/password",
						"/:customerKey/p/:portalKey/documents",
						"/:customerKey/p/:portalKey/documents/:recordsKey(Policy)",
						"/:customerKey/p/:portalKey/records/:recordsKey",
						"/:customerKey/p/:portalKey/records",
						"/:customerKey/p/:portalKey/training/:trainingKey",
						"/:customerKey/p/:portalKey/training",
						"/:customerKey/p/:portalKey/:formsTab(forms|drafts|queue)",
						"/:customerKey/p/:portalKey/actions/:action(create|edit|detail|complete)?/:actionId?",
						"/:customerKey/p/:portalKey/actions/:action(create|edit|detail|complete)?/:actionId?/comments/edit/:commentId?",
						"/:customerKey/p/:portalKey/actions/:action(create|edit|detail|complete)?/:actionId?/comments/:commentId?",
						"/:customerKey/p/:portalKey",
					]}
				>
					<HomePage />
				</Route>

				<Route path="/:customerKey/p/:portalKey/authentication/callback*">
					<Loading
						text={t("display:authentication.authenticating")}
						withHistory={false}
					/>
				</Route>

				<Route path="/:customerKey/p/:portalKey/newAppId/:newAppId">
					<NewAppId />
				</Route>

				<Route path={["/:customerKey/p/:portalKey/auditindexeddb"]}>
					<AuditPage />
				</Route>

				<Route path="*">
					<Error pageNotFound />
				</Route>
			</Switch>
		</DownloadManagerProvider>
	);
};

export const Root = () => {
	const theme = useSelector<State, string | null>((state) => {
		if (state.portal && state.portal.portals[0] && state.portal.portals[0].theme) {
			return state.portal.portals[0].theme;
		}

		return null;
	});

	return (
		<Suspense fallback={<Loading />}>
			{theme && createPortal(<style>{theme.replace(/,/g, ";")}</style>, document.head)}
			<BrowserRouter>
				<ErrorBoundary>
					<Authentication>
						<UnsupportedBrowser />
						<RootInner />
					</Authentication>
				</ErrorBoundary>
			</BrowserRouter>
		</Suspense>
	);
};
