import { PageNavigation } from "../PageNavigation";
import logo from "../../../../assets/png/new-she-logo.png";
import "./PageHeader.styles.scss";

interface PageHeaderProps {
	brandLogo?: any;
	brandTitle?: string;
}

export const PageHeader = ({ brandLogo, brandTitle }: PageHeaderProps) => {
	const src = brandLogo ? brandLogo : logo;
	return (
		<header className="she-page-header">
			<div className="she-page-header__inner">
				<div className="she-page-header__logo">
					<div className="she-page-header__logo-container">
						{brandLogo ? (
							<img
								alt={brandTitle || "Portal Logo"}
								className="she-page-header__logo-image"
								src={src}
							/>
						) : null}
					</div>
				</div>

				<PageNavigation />
			</div>
		</header>
	);
};
