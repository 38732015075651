import { NavTab } from "./NavTab";
import type { TabProps } from "./NavTab";
import "./NavTabGroup.styles.scss";

interface Props {
	tabs: TabProps[];
}

export const NavTabGroup = ({ tabs }: Props) => {
	return (
		<div role="navigation">
			<ul className="she-nav-tab__group">
				{tabs.map((t) => (
					<NavTab {...t} key={t.to} />
				))}
			</ul>
		</div>
	);
};
