import { useState, useCallback, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "oidc-react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useDetailedSubmitError } from "../../../../utilities/UseDetailedSubmitError";
import { PortalViewType, PortalAuthenticationMode } from "../../../../../models/portal";
import { DefaultLocalDataService } from "../../../../../services/localData";
import { QueuedRecord } from "../QueuedRecord";
import { getDateFormat } from "../../../../../helpers/DateTimeInputHelper";
import { QuestionnaireProgressState } from "../../../../../models/questionnaire";
import { ConfirmationModal } from "../../../../components/modals";
import { Loading } from "../../../../components/Loading";
import { UploadSingleton } from "../../../../../workers/upload/upload";
import { IllustratedThemedModal } from "../../../../components/modals/IllustratedThemedModal";
import { ReactComponent as ErrorIllustration } from "../../../../assets/svg/illustrations/caution.svg";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { useResetHighlight } from "../../../../../helpers/UseHighlight";
import { rgLog } from "../../../../../services/log";
import type { Portal, RecordStub } from "../../../../../models/portal";
import type { StaticContext } from "react-router";
import type { RouteComponentProps } from "react-router-dom";
import type { HistoryLocationState } from "../../../../../types/History";

interface Props extends RouteComponentProps<any, StaticContext, HistoryLocationState> {
	portal: Portal;
}
export const PortalQueuedView = withRouter((props: Props) => {
	const { t } = useTranslation();
	const portalStubs = props.portal.questionnaireStubs;
	const [questionnaireRecords, setQuestionnaireRecords] = useState<RecordStub[]>([]);
	const [dateFormat, setDateFormat] = useState("");
	const [recordToDeleteId, setRecordToDeleteId] = useState("");
	const [contextMenu, setContextMenu] = useState<HTMLButtonElement | null>(null);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [anonymousRecordWarningId, setAnonymousRecordWarningId] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [showSubmitError, setShowSubmitError] = useState(false);
	const portalPath = usePortalPath();
	const [submitError, setSubmitError] = useState<string | undefined>();
	const auth = useAuth();

	const sortFunc = (a: RecordStub, b: RecordStub) => {
		return a.dateCompleted < b.dateCompleted ? 1 : -1;
	};

	const getQueuedRecords = useCallback(async () => {
		const localDataService = new DefaultLocalDataService();
		const records = await localDataService.getQueuedQuestionnaires(
			props.portal.key,
			auth.userData || null,
		);

		const recordStubs: RecordStub[] = [];
		for (const record of records) {
			const templateStub = portalStubs.find(
				(s) => s.key === record.questionnaire.templateId.toString(),
			);
			if (templateStub !== undefined && templateStub !== null) {
				const recordStub: RecordStub = {
					id: record.questionnaire.id,
					name: record.questionnaire.name,
					dateCreated: record.status.dateCreated ? record.status.dateCreated : new Date(),
					templateStub,
					state: record.status.questionnaireState,
					dateCompleted: record.status.dateCompleted
						? record.status.dateCompleted
						: new Date(),
					highlight:
						props.location.state &&
						props.location.state.highlightRecord === record.questionnaire.id,
					userId: record.userId,
				};

				recordStubs.push(recordStub);
			}
		}

		recordStubs.sort(sortFunc);
		setQuestionnaireRecords(recordStubs);
	}, [portalStubs, props.location.state, props.portal.key, auth.userData]);

	useResetHighlight(props.history, props.location);

	const openRecord = useCallback(
		(recordId: string) => {
			const record = questionnaireRecords.find((q) => q.id === recordId);
			if (
				record !== undefined &&
				record.state &&
				record.state === QuestionnaireProgressState.UploadFailed
			) {
				props.history.replace(props.location.pathname, {
					...props.location.state,
					highlightRecord: recordId,
				} as any);
				props.history.push(`${portalPath}${PortalViewType.Queued}/${recordId}`);
			}
		},
		[
			questionnaireRecords,
			props.history,
			portalPath,
			props.location.pathname,
			props.location.state,
		],
	);

	const onRecordSelected = useCallback(
		(recordId: string) => {
			const record = questionnaireRecords.find((q) => q.id === recordId);
			if (
				record !== undefined &&
				record.state &&
				record.state === QuestionnaireProgressState.UploadFailed
			) {
				if (record.userId) {
					openRecord(recordId);
				} else if (
					auth.userData &&
					props.portal.authenticationMode !== PortalAuthenticationMode.Public
				) {
					setAnonymousRecordWarningId(recordId);
				} else {
					openRecord(recordId);
				}
			}
		},
		[questionnaireRecords, openRecord, auth.userData, props.portal.authenticationMode],
	);

	const onRecordSelectKeyboard = useCallback(
		(recordId: string, keyPressed: string) => {
			if (keyPressed === "Enter" || keyPressed === " ") {
				onRecordSelected(recordId);
			}
		},
		[onRecordSelected],
	);

	const onRemoveRecord = useCallback(
		(id: string, contextMenuButton: HTMLButtonElement | null) => {
			setRecordToDeleteId(id);
			setContextMenu(contextMenuButton);
			setShowRemoveModal(true);
		},
		[],
	);

	const detailedSubmitError = useDetailedSubmitError();

	const onUploadRecord = useCallback(
		(id: string, contextMenuButton: HTMLButtonElement | null) => {
			setContextMenu(contextMenuButton);
			setIsLoading(true);
			UploadSingleton.getInstance()
				.UploadForm(id)
				.then((res) => {
					if (res === true) {
						setIsLoading(false);
						getQueuedRecords();
					} else {
						setSubmitError(detailedSubmitError(res));
						setShowSubmitError(true);
					}
				})
				.catch((e) => {
					rgLog("send", e);
					setSubmitError(detailedSubmitError(e));
					setShowSubmitError(true);
				});
		},
		[getQueuedRecords, detailedSubmitError],
	);

	if (dateFormat.length === 0) {
		setDateFormat(getDateFormat());
	}

	const onRemoveRecordConfirmation = useCallback(async () => {
		const localDataService = new DefaultLocalDataService();
		await localDataService.deleteQuestionnaire(recordToDeleteId);
		window.dispatchEvent(new Event("uploadRefresh"));
		setShowRemoveModal(false);
	}, [recordToDeleteId]);

	const onRemoveRecordCancel = useCallback(() => {
		if (contextMenu) {
			contextMenu.focus();
		}
		setShowRemoveModal(false);
	}, [contextMenu]);

	useEffect(() => {
		getQueuedRecords();
	}, [getQueuedRecords]);

	const eventListener = useCallback(() => {
		getQueuedRecords();
	}, [getQueuedRecords]);

	useEffect(() => {
		window.addEventListener("uploadRefresh", eventListener);
		return () => window.removeEventListener("uploadRefresh", eventListener);
	}, [eventListener]);

	return (
		<>
			<header>
				<h2>{t("display:labelQueuedHeader")}</h2>
			</header>
			<section>
				<ul className="she-record-list">
					<TransitionGroup component={null}>
						{questionnaireRecords.map((q) => (
							<CSSTransition
								appear
								classNames="fade"
								component={null}
								key={q.id}
								timeout={500}
							>
								<QueuedRecord
									dateFormat={dateFormat}
									key={q.id}
									onRecordSelectKeyboard={onRecordSelectKeyboard}
									onRecordSelected={onRecordSelected}
									onRemoveRecord={onRemoveRecord}
									onUploadRecord={onUploadRecord}
									recordStub={q}
								/>
							</CSSTransition>
						))}
					</TransitionGroup>
				</ul>
			</section>
			<IllustratedThemedModal
				cancelText={t("global:ok")}
				onCancel={() => {
					setIsLoading(false);
					setShowSubmitError(false);
				}}
				show={showSubmitError}
				showEntryAnimation={false}
				text={submitError || t("display:labelSubmitErrorMessage")}
			>
				<ErrorIllustration />
			</IllustratedThemedModal>
			<ConfirmationModal
				cancelText={t("display:buttonNoCancel")}
				confirmText={t("display:buttonYesRemove")}
				onCancel={onRemoveRecordCancel}
				onConfirm={onRemoveRecordConfirmation}
				show={showRemoveModal}
				text={t("display:labelAreYouSureRemoveRecord")}
			/>
			<ConfirmationModal
				cancelText={t("global:cancel")}
				confirmText={t("global:continue")}
				onCancel={() => setAnonymousRecordWarningId("")}
				onConfirm={() => openRecord(anonymousRecordWarningId)}
				show={!!anonymousRecordWarningId}
				text={t("display:labelAnonymousRecordWarning")}
			/>
			<Loading
				show={isLoading}
				showEntryAnimation={false}
				text={t("display:labelUploading")}
			/>
		</>
	);
});
