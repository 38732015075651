import { DefaultLocalDataService } from "../../services/localData";
import { DefaultQuestionnaireService } from "../../services/questionnaire";
import {
	HealthMonitorSingleton,
	OnlineStatus,
} from "../../services/health/implementations/healthMonitor";
import { QuestionnaireProgressState } from "../../models/questionnaire";
import { store } from "../../state/store";
import { rgLog } from "../../services/log";
import { clearOrphanedAttachments } from "../../services/utilities/AttachmentHelpers";
import { getAuthUser } from "../../helpers/AuthenticationHelpers";
import { rgEvent } from "../../services/log/log";
import { isBrowserIE } from "../../helpers/ieHelper";
import { isAttachmentSizeCorrect } from "../../helpers/FileHelper";
import type { User } from "oidc-client-ts";
import type { Attachment } from "../../models/attachments/Attachment";
import type { QuestionnaireTemplate } from "../../models/questionnaire";
import type { LocalDataService } from "../../services/localData";

export class UploadSingleton {
	private static instance: UploadSingleton;
	private uploadInterval = 30000;
	private dataService: LocalDataService;
	private showUploadError = true;
	private portalKey = "";
	private customerKey = "";

	private constructor() {
		this.dataService = new DefaultLocalDataService();
	}

	static getInstance() {
		if (!UploadSingleton.instance) {
			UploadSingleton.instance = new UploadSingleton();
			const state = store.getState();
			UploadSingleton.getInstance().portalKey = state.portal.portals[0].key;
			UploadSingleton.getInstance().customerKey = state.portal.portals[0].customerKey;
			if (!isBrowserIE()) {
				window.setInterval(
					UploadSingleton.getInstance().CheckForQueuedUploads,
					UploadSingleton.getInstance().uploadInterval,
				);
			}
		}
		return UploadSingleton.instance;
	}

	private RecordError(form: QuestionnaireTemplate, errors: string[], user: User | null) {
		const instance = UploadSingleton.getInstance();
		form.status.questionnaireState = QuestionnaireProgressState.UploadFailed;
		form.status.errors = errors;
		form.status.isSubmittingInProgress = false;
		instance.dataService.saveQuestionnaire(form, form.userId ? user : null);
		window.dispatchEvent(new Event("uploadRefresh"));
	}

	private async CheckForQueuedUploads() {
		let user = null;
		try {
			user = await getAuthUser();
		} catch (e) {
			rgLog("send", e);
			return;
		}
		const healthInstance = HealthMonitorSingleton.getInstance();
		if (healthInstance.isAPIavailable() === OnlineStatus.Available) {
			const instance = UploadSingleton.getInstance();
			const form = await instance.dataService.getQuestionnaireForSubmission(
				instance.portalKey,
				user,
			);
			const attachments =
				form &&
				(await instance.dataService.loadRecordAttachments(form.questionnaire.id, user));
			if (form) {
				rgEvent("Form Submitted from queue", { questionnaireId: form.questionnaire.id });
				instance.Upload(form, user, attachments).catch((e) => {
					rgLog("send", e);
				});
			}
		}
	}

	private async Upload(
		form: QuestionnaireTemplate,
		user: User | null,
		storedAttachments?: Attachment[],
	) {
		const instance = UploadSingleton.getInstance();

		let attachments: Attachment[] = [];

		if (storedAttachments) {
			attachments = storedAttachments.map((storedAttachment) => {
				const attachment = {
					...storedAttachment,
					file: new Blob([storedAttachment.file]),
				} as Attachment;
				return attachment;
			});
		}

		if (form) {
			try {
				form.status.questionnaireState = QuestionnaireProgressState.Uploading;
				instance.dataService.saveQuestionnaire(form, form.userId ? user : null);

				const questionnaireService = new DefaultQuestionnaireService({
					subdomain: "Questionnaire",
				});
				attachments = clearOrphanedAttachments(attachments, form.subModuleRecords);
				let publicAuthToken;
				if (user && !form.userId) {
					publicAuthToken = store.getState().auth.token;
				}

				for (const attachment of attachments) {
					if (!isAttachmentSizeCorrect(attachment)) {
						rgLog("send", {
							error: new Error(
								"Attachment file size too small when uploading from queue",
							),
							customData: { fileSize: attachment.file.size },
						});
					}
				}

				const res = await questionnaireService.submitQuestionnaire(
					form,
					attachments,
					publicAuthToken,
				);
				if (res.isSaved) {
					await instance.dataService.deleteQuestionnaire(form.questionnaire.id);
					window.dispatchEvent(new Event("uploadRefresh"));
				} else {
					instance.RecordError(form, res.errors, user);
					throw new Error(
						res.errors
							? res.errors.toString()
							: "Error happened when uploading form from the queue",
					);
				}
			} catch (e) {
				instance.RecordError(form, e as any, user);
				throw e;
			}
		}
	}

	public async UploadForm(questionnaireId: string): Promise<boolean> {
		const instance = UploadSingleton.getInstance();
		let user = null;
		try {
			user = await getAuthUser();
		} catch (e) {
			rgLog("send", e);
			return false;
		}
		const form = await instance.dataService.getQuestionnaireById(questionnaireId, user);
		const attachments =
			form && (await instance.dataService.loadRecordAttachments(form.questionnaire.id, user));
		let result = false;
		if (form) {
			rgEvent("Form Uploaded", { questionnaireId: form.questionnaire.id });
			result = await instance
				.Upload(form, user, attachments)
				.then(() => {
					return true;
				})
				.catch((e) => {
					rgLog("send", e);
					// TODO: What should this actually be returning, according to the type it should be a boolean
					// eslint-disable-next-line @typescript-eslint/no-unsafe-return
					return e;
				});
		}

		return result;
	}

	public async HasFailedUploads(user: User | null): Promise<boolean> {
		const instance = UploadSingleton.getInstance();
		return await instance.dataService.hasFailedUploads(instance.portalKey, user);
	}

	public RegisterBackgroundSync() {
		// If `serviceWorker` is registered and ready
		navigator.serviceWorker.ready.then((registration) => {
			console.log(registration);
			// Registering `background sync` event
			// @ts-expect-error - We need to fix the serviceWorker types as sync has been removed from the base definitions in typescript see: https://stackoverflow.com/a/69832217
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
			return registration.sync.register("bainUpload").then(
				(rs: any) => {
					console.log(rs);
				},
				() => {
					console.error("Background sync registered failed.");
				},
			);
		});
	}

	public SetDisplayUploadError(val: boolean) {
		UploadSingleton.getInstance().showUploadError = val;
	}

	public async ShouldDisplayUploadError(user: User | null): Promise<boolean> {
		const hasErrors = await this.HasFailedUploads(user);
		const showError = UploadSingleton.getInstance().showUploadError && hasErrors;
		return showError;
	}
}
