import "./FormLayout.styles.scss";

interface Props {
	children: React.ReactNode;
	header?: React.ReactNode;
	nav?: React.ReactNode;
}

const FormLayout = ({ children, header, nav }: Props) => {
	return (
		<div className="she-layout-form">
			{header}
			<article className="she-row">
				{nav}
				<main className="she-layout-form__content">
					<div className="she-layout-form__content-column">{children}</div>
				</main>
			</article>
		</div>
	);
};

export { FormLayout };
