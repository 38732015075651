import { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DefaultAttachmentService } from "../../../../../services/attachment";
import { isUrlAllowed } from "../../../../utilities/IsUrlAllowed";
import { DownloadManagerContext } from "../../../../components/DownloadManagerContext/DownloadManagerContext";
import { Download } from "../../../../components/DownloadManagerContext/Download";
import { isIosSafari, isIosStandalone } from "../../../../../helpers/SafariHelper";
import { getPortalCulture } from "../../../../../helpers/DateTimeInputHelper";
import { rgLog } from "../../../../../services/log";
import { getCustomerKey } from "../../../../../helpers/AuthenticationHelpers";
import { Button } from "../../../../components/input/Button";
import { ReactComponent as DownloadIcon } from "../../../../assets/svg/download--dark.svg";
import { ReactComponent as ExternalIcon } from "../../../../assets/svg/external-link.svg";
import type { AttachmentService } from "../../../../../services/attachment";
import type { Attachment } from "../../../../../models/policy";

interface Props {
	attachment?: Attachment;
}

export const PolicyAttachmentsItem = ({ attachment }: Props) => {
	const portalCulture = getPortalCulture();
	const { t } = useTranslation();

	const [linkUrl, setLinkUrl] = useState("/");
	const [isFile, setIsFile] = useState(false);
	const [isSupportedBrowser, setIsSupportedBrowser] = useState(false);
	const downloadContext = useContext(DownloadManagerContext);

	const downloadLabel = useMemo(
		() =>
			isIosSafari() && isIosStandalone()
				? t("display:labelOpenFile")
				: t("display:labelDownloadFile"),
		[t],
	);

	const handleClickGetFile = () => {
		const customerKey = getCustomerKey();

		if (attachment && attachment.attachmentType === "File") {
			const attachmentService: AttachmentService = new DefaultAttachmentService({
				subdomain: "Attachment",
			});

			const download = new Download(
				() =>
					attachmentService.getOneByGuidAndCustomerKey(
						attachment.identifier as string,
						customerKey,
					),
				attachment.fileName || "downloadedAttachment",
			);
			const description = [];
			attachment.fileDescription && description.push(attachment.fileDescription);
			attachment.dateCreated &&
				description.push(
					`${t("display:labelDateAdded")}: ${moment(attachment.dateCreated).format(
						portalCulture === "Us" ? "MM/DD/YYYY" : "DD/MM/YYYY",
					)}`,
				);
			description.length && (download.description = description);
			downloadContext.addDownload(download);
		}
	};

	useEffect(() => {
		tryBlobSupported(setIsSupportedBrowser(true));
	}, []);

	useEffect(() => {
		if (attachment) {
			if (attachment.attachmentType === "File") {
				setIsFile(true);
			}

			if (attachment.attachmentType === "Link" && attachment.link) {
				setLinkUrl((isUrlAllowed(attachment.link) ? "" : "https://") + attachment.link);
			}
		}
	}, [attachment]);

	return (
		<li className="she-policy-list-item">
			<div className="she-policy-list-item__wrap">
				<h1 className="she-policy-list-item__text she-policy-list-item__text--heading">
					{attachment && (attachment.fileDescription || attachment.fileName)}
				</h1>
				<p className="she-policy-list-item__text">{attachment!.policyTitle}</p>
				<p className="she-policy-list-item__text">
					{t("display:labelDateAdded")}:{" "}
					{attachment &&
						attachment.dateCreated &&
						moment(attachment.dateCreated).format(
							portalCulture === "Us" ? "MM/DD/YYYY" : "DD/MM/YYYY",
						)}
				</p>
				<div className="she-policy-list-item__button-container">
					{isFile && !isSupportedBrowser ? (
						<p>
							<small>Please use an alternative browser to access downloads</small>
						</p>
					) : isFile && isSupportedBrowser ? (
						<Button
							icon={<DownloadIcon />}
							onClick={handleClickGetFile}
							variant="secondary"
						>
							{attachment && `${downloadLabel} ${attachment.fileSize}MB`}
						</Button>
					) : (
						<a href={linkUrl} rel="noreferrer noopener" target="_blank">
							<Button icon={<ExternalIcon />} variant="secondary">
								{t("display:labelOpenLink")}
							</Button>
						</a>
					)}
				</div>
			</div>
		</li>
	);

	function tryBlobSupported(blobSupportedTask: any) {
		try {
			const isFileSaverSupported = !!new Blob();
			if (isFileSaverSupported) {
				return blobSupportedTask;
			}
		} catch (e) {
			rgLog("send", e);
		}
	}
};
