import { rgLog } from "../../../services/log";

export const FeaturesPage = () => {
	return (
		<div className="she-features-wrapper">
			<div className="she-components-control">
				<span>Send raygun message:</span>
				<div className="she-components-control">
					<button
						className="she-btn she-btn-primary she-features-button"
						id="sendRaygunMessage"
						onClick={() => {
							rgLog("send", {
								error: new Error("internal test error"),
							});
						}}
						type="button"
					>
						Send Error message
					</button>
				</div>
				<div className="she-components-control">
					<button
						className="she-btn she-btn-primary she-features-button"
						id="sendRaygunMessage"
						onClick={() => {
							rgLog("send", new Error("alternate internal test error"));
						}}
						type="button"
					>
						Send Alternate message
					</button>
				</div>
			</div>
		</div>
	);
};
