import type { MessageToSend, rg4jsMessageEvent } from "./types";

export const isAfterSendTimeConfiguration = (
	currentDateTime: Date,
	previousDateTime: Date,
	duration: number | string | undefined = process.env.REACT_APP_RAYGUN_SEND_INTERVAL || 3600000,
) => currentDateTime.getTime() - previousDateTime.getTime() > Number(duration);

const isMessageErrorObject = (message: any) => "error" in message;
const isMessageError = (message: any) => !isMessageErrorObject(message);

export const checkMessageEquality = (previousMessage: any, nextMessage: any) => {
	if (isMessageErrorObject(previousMessage) && isMessageError(nextMessage)) {
		return previousMessage.error.message === nextMessage.message;
	}

	if (isMessageError(previousMessage) && isMessageErrorObject(nextMessage)) {
		return previousMessage.message === nextMessage.error.message;
	}

	if (isMessageErrorObject(previousMessage) && isMessageErrorObject(nextMessage)) {
		return previousMessage.error.message === nextMessage.error.message;
	}

	return previousMessage.message === nextMessage.message;
};

export const isMessageEventEqual = (
	previousEvent: rg4jsMessageEvent,
	nextEvent: rg4jsMessageEvent,
) => previousEvent === nextEvent;

export const areEventMessagesEqual = (
	previousMessage: MessageToSend,
	nextMessage: MessageToSend,
) => {
	if (
		!isMessageEventEqual(previousMessage.event, nextMessage.event) ||
		!checkMessageEquality(previousMessage.message, nextMessage.message)
	) {
		return false;
	}

	return true;
};
