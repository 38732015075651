import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IllustratedThemedModal } from "../../modals/IllustratedThemedModal";
import { isFileImage, isSVG, resizeImageFile } from "../../../../helpers/FileHelper";
import { Loading } from "../../Loading";
import { NotificationModal } from "../../modals";
import { ReactComponent as FilesTooBig } from "../../../assets/svg/illustrations/fileTooBig.svg";
import { rgLog } from "../../../../services/log";
import type { Portal } from "../../../../models/portal";
import type { State } from "../../../../state";
import "./BasicFileInput.styles.scss";

interface Props {
	name: string;
	onAddAttachments: (files: File[]) => void;
}

export const BasicFileInput = ({ name, onAddAttachments }: Props) => {
	const { t } = useTranslation();
	const portal = useSelector<State, Portal>((state) => state.portal.portals[0]);

	const [validationModal, setValidationModal] = useState(false);
	const handleCloseValidationModal = () => setValidationModal(false);

	const [filesTooBigModal, setFilesTooBigModal] = useState(false);
	const [filesTooBig, setFilesTooBig] = useState<{ fileName: string; fileSize: string }[]>([]);
	const handleCloseFilesTooBigModal = () => {
		setFilesTooBigModal(false);
		setFilesTooBig([]);
	};

	const [processingFiles, setProcessingFiles] = useState(false);

	const validateFileName = useCallback(
		(file: File) => {
			const formattedType = file.name
				.substring(file.name.lastIndexOf(".") + 1)
				.toLocaleLowerCase();
			return portal.excludedFileTypes.includes(formattedType);
		},
		[portal],
	);

	const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const files = Array.from(event.target.files);
			event.target.value = "";

			setFilesTooBigModal(false);
			setFilesTooBig([]);

			// Validate excluded file extensions
			if (files.some((file) => validateFileName(file))) {
				setValidationModal(true);
				return;
			}

			let newFiles: File[] = [];

			// Resize images bigger than the 2Mb
			for (const file of files) {
				if (isFileImage(file) && !isSVG(file) && file.size > 1024 * 1024 * 2) {
					setProcessingFiles(true);
					try {
						const newFile = await resizeImageFile(file);
						if (newFile && newFile.size > 1024 * 100) {
							// iOS bug, accept only image with some size
							newFiles.push(newFile as File);
							break;
						} else {
							rgLog("send", {
								error: new Error("Compressed image is too small"),
								customData: { fileSize: newFile.size },
							});
						}
					} catch (e) {
						rgLog("send", e);
					}
				}
				newFiles.push(file);
			}

			// Validate sizes
			newFiles = newFiles.reduce<File[]>((acc, file) => {
				if (file.size / 1024 < portal.attachmentSizeLimit) {
					acc.push(file);
				} else {
					const tooBig = {
						fileName: file.name,
						fileSize: `${
							file.size / 1024 < 1024
								? `${(file.size / 1024).toFixed(2)} Kb`
								: `${(file.size / 1024 / 1024).toFixed(2)} Mb`
						}`,
					};
					setFilesTooBig((filesTooBig) => [...filesTooBig, tooBig]);
					setFilesTooBigModal(true);
				}
				return acc;
			}, []);

			onAddAttachments(newFiles);
			setProcessingFiles(false);
		}
	};

	return (
		<>
			<div className="basic-file-input">
				<div>
					<input
						className="basic-file-input__input"
						id={`file-input-${name}`}
						multiple
						onChange={handleOnChange}
						type="file"
					/>
					<label
						className="basic-file-input__label she-btn she-btn-tertiary"
						htmlFor={`file-input-${name}`}
					>
						{t("display:buttonAddAttachment")}
					</label>
				</div>
			</div>

			<NotificationModal
				content={t("validation:unacceptableFileTypeMessage", {
					excludedFileTypes: portal.excludedFileTypes
						.split(".")
						.filter((type) => type)
						.join(", "),
				})}
				onClose={handleCloseValidationModal}
				show={validationModal}
			/>

			<IllustratedThemedModal
				cancelText={t("global:ok")}
				onCancel={handleCloseFilesTooBigModal}
				show={filesTooBigModal}
				text={t("validation:filesTooBigMessage", {
					maxFilesSize: `${
						// TODO: Look into this - we should not have to asset is existence
						portal.attachmentSizeLimit < 1024
							? `${portal.attachmentSizeLimit} Kb`
							: `${(portal.attachmentSizeLimit / 1024).toFixed(2)} Mb`
					}`,
				})}
				withHistory={false}
			>
				<FilesTooBig />
				<div className="she-large-file-wrapper">
					{filesTooBig.map((f: { fileName: string; fileSize: string }, i: number) => (
						<div className="she-large-file" key={i}>
							<div>{f.fileName}</div>
							<div>{f.fileSize}</div>
						</div>
					))}
				</div>
			</IllustratedThemedModal>

			{processingFiles && (
				<Loading
					show={processingFiles}
					text={t("display:labelProcessing")}
					withHistory={false}
				/>
			)}
		</>
	);
};
