import { useAuth } from "oidc-react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { validate } from "uuid";
import { usePortalPath } from "../../../helpers/UsePortalPath";
import { NotificationModal } from "../modals";

export const NewAppId = () => {
	const { newAppId } = useParams<{ newAppId: string }>();
	const { signOutRedirect, userData } = useAuth();
	const history = useHistory();
	const portalPath = usePortalPath();
	const { t } = useTranslation();

	const isCorrectFormat = validate(newAppId);

	let message = t("display:labelApplicationIDInvalidFormat");
	if (isCorrectFormat) {
		if (userData) {
			message = t("display:labelApplicationIDWillBeChangedPleaseSignIn");
		} else {
			message = t("display:labelApplicationIDWillBeChanged");
		}
	}

	const handleClose = () => {
		if (isCorrectFormat) {
			localStorage.setItem("raygun4js-userid", newAppId);
			if (userData) {
				signOutRedirect();
			} else {
				history.replace(portalPath);
			}
		} else {
			history.replace(portalPath);
		}
	};

	return (
		<NotificationModal
			closeText={t("global:ok")}
			content={message}
			onClose={handleClose}
			show
			withHistory={false}
		/>
	);
};
