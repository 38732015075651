import { useCallback, useState, useEffect, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withRouter } from "react-router-dom";
import { useAuth } from "oidc-react";
import { DefaultLocalDataService } from "../../../../../services/localData";
import { PortalViewType, PortalAuthenticationMode } from "../../../../../models/portal";
import { InProgressRecord } from "../InProgressRecord";
import { ConfirmationModal } from "../../../../components/modals";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { useResetHighlight } from "../../../../../helpers/UseHighlight";
import { FormsStatsContext } from "../FormsStatsContext/FormsStatsContext";
import { QuestionnaireProgressState } from "../../../../../models/questionnaire";
import { rgEvent } from "../../../../../services/log/log";
import { getDateFormat } from "../../../../../helpers/DateTimeInputHelper";
import type { QuestionnaireTemplate } from "../../../../../models/questionnaire";
import type { RecordStub, Portal } from "../../../../../models/portal";
import type { RouteComponentProps } from "react-router-dom";
import type { StaticContext } from "react-router";
import type { HistoryLocationState } from "../../../../../types/History";
import "./InProgressView.styles.scss";

interface Props extends RouteComponentProps<any, StaticContext, HistoryLocationState> {
	portal: Portal;
}

export const PortalInProgressView = withRouter((props: Props) => {
	const { t } = useTranslation();
	const portalStubs = props.portal.questionnaireStubs;
	const [questionnaireRecords, setQuestionnaireRecords] = useState<RecordStub[]>([]);
	const [recordToDeleteId, setRecordToDeleteId] = useState("");
	const [contextMenu, setContextMenu] = useState<HTMLButtonElement | null>(null);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [anonymousRecordWarningId, setAnonymousRecordWarningId] = useState("");
	const dateFormat = useMemo(() => getDateFormat(), []);
	const portalPath = usePortalPath();
	const { refreshStats } = useContext(FormsStatsContext);
	const auth = useAuth();

	const sortFunc = (a: RecordStub, b: RecordStub) => {
		return a.dateCreated < b.dateCreated ? 1 : -1;
	};

	const getInProgressRecords = useCallback(async () => {
		const localDataService = new DefaultLocalDataService();
		const records = await localDataService.getQuestionnaireByState(
			props.portal.key,
			QuestionnaireProgressState.InProgress,
			auth.userData || null,
		);

		const recordStubs: RecordStub[] = [];
		for (const record of records) {
			const templateStub = portalStubs.find(
				(s) => s.key === record.questionnaire.templateId.toString(),
			);
			if (templateStub !== undefined && templateStub !== null) {
				const recordStub: RecordStub = {
					id: record.questionnaire.id,
					name: record.questionnaire.name,
					dateCreated: record.status.dateCreated ? record.status.dateCreated : new Date(),
					templateStub,
					dateCompleted: record.status.dateCreated
						? record.status.dateCreated
						: new Date(),
					highlight: props.location.state?.highlightRecord === record.questionnaire.id,
					userId: record.userId,
				};

				recordStubs.push(recordStub);
			}
		}
		recordStubs.sort(sortFunc);
		setQuestionnaireRecords(recordStubs);
		refreshStats();
	}, [portalStubs, props.location.state, props.portal.key, refreshStats, auth.userData]);

	useResetHighlight(props.history, props.location);

	const openRecord = useCallback(
		async (recordId: string) => {
			const record = questionnaireRecords.find((q) => q.id === recordId);
			if (record !== undefined) {
				props.history.replace(props.location.pathname, {
					...props.location.state,
					highlightRecord: recordId,
				} as any);

				if (auth.userData && record.userId === "") {
					const localDataService = new DefaultLocalDataService();
					const questionnaire = await localDataService.getQuestionnaireById(
						recordId,
						null,
					);
					const attachments = await localDataService.loadRecordAttachments(
						recordId,
						null,
					);
					await localDataService.saveQuestionnaire(
						questionnaire as QuestionnaireTemplate,
						auth.userData,
					);
					await localDataService.addAttachments(attachments, auth.userData);
				}
				props.history.push(`${portalPath}${PortalViewType.InProgress}/${recordId}`);
			}
		},
		[
			questionnaireRecords,
			props.history,
			portalPath,
			props.location.pathname,
			props.location.state,
			auth.userData,
		],
	);

	const onRecordSelected = useCallback(
		(recordId: string) => {
			const record = questionnaireRecords.find((q) => q.id === recordId);
			if (record !== undefined) {
				if (record.userId) {
					openRecord(recordId);
				} else if (
					auth.userData &&
					props.portal.authenticationMode !== PortalAuthenticationMode.Public
				) {
					setAnonymousRecordWarningId(recordId);
				} else {
					openRecord(recordId);
				}
			}
		},
		[questionnaireRecords, openRecord, auth.userData, props.portal.authenticationMode],
	);

	const onRecordSelectKeyboard = useCallback(
		(recordId: string, keyPressed: string) => {
			if (keyPressed === "Enter" || keyPressed === " ") {
				onRecordSelected(recordId);
			}
		},
		[onRecordSelected],
	);

	const onRemoveRecord = useCallback(
		(id: string, contextMenuButton: HTMLButtonElement | null) => {
			setRecordToDeleteId(id);
			setContextMenu(contextMenuButton);
			setShowRemoveModal(true);
		},
		[],
	);

	const onRemoveRecordConfirmation = useCallback(async () => {
		rgEvent("Form Deleted from In progress", { questionnaireId: recordToDeleteId });
		const localDataService = new DefaultLocalDataService();
		await localDataService.deleteQuestionnaire(recordToDeleteId);
		await getInProgressRecords();
		setShowRemoveModal(false);
	}, [recordToDeleteId, getInProgressRecords]);

	const onRemoveRecordCancel = useCallback(() => {
		if (contextMenu) {
			contextMenu.focus();
		}
		setShowRemoveModal(false);
	}, [contextMenu]);

	useEffect(() => {
		getInProgressRecords();
	}, [getInProgressRecords]);

	return (
		<>
			<header>
				<h2>{t("display:labelInProgressHeader")}</h2>
			</header>
			<section>
				<ul className="she-record-list">
					<TransitionGroup component={null}>
						{questionnaireRecords.map((questionnaireRecord) => (
							<CSSTransition
								appear
								classNames="fade"
								component={null}
								key={questionnaireRecord.id}
								timeout={500}
							>
								<InProgressRecord
									dateFormat={dateFormat}
									onRecordSelectKeyboard={onRecordSelectKeyboard}
									onRecordSelected={onRecordSelected}
									onRemoveRecord={onRemoveRecord}
									recordStub={questionnaireRecord}
								/>
							</CSSTransition>
						))}
					</TransitionGroup>
				</ul>
			</section>
			<ConfirmationModal
				cancelText={t("display:buttonNoCancel")}
				confirmText={t("display:buttonYesRemove")}
				onCancel={onRemoveRecordCancel}
				onConfirm={onRemoveRecordConfirmation}
				show={showRemoveModal}
				text={t("display:labelAreYouSureRemoveRecord")}
			/>
			<ConfirmationModal
				cancelText={t("global:cancel")}
				confirmText={t("global:continue")}
				onCancel={() => setAnonymousRecordWarningId("")}
				onConfirm={() => openRecord(anonymousRecordWarningId)}
				show={!!anonymousRecordWarningId}
				text={t("display:labelAnonymousRecordWarning")}
			/>
		</>
	);
});
