import { useEffect } from "react";
import { ConfigPropertyKey, getConfigurationOption } from "../../helpers/ConfigHelper";
import { getAuthUser, getCustomerKey } from "../../helpers/AuthenticationHelpers";
import { rgEvent } from "../log/log";

/**
 * Wrapped gainsight, allows for sending user data to the Gainsight API
 */
const gainsightLogin = (
	gainsightId: string,
	applicationId: string,
	customerKey: string,
	authenticated: boolean,
) => {
	const maxRetries = 3;
	const retryDelay = 5000;
	let retries = 0;

	const retryOperation = () => {
		try {
			aptrinsic(
				"identify",
				{ id: gainsightId, applicationId, authenticated },
				{ id: customerKey },
			);
			rgEvent("Successfully completed", { id: gainsightId, applicationId });
		} catch (error) {
			rgEvent(`Attempt ${retries + 1} failed: ${error}`, { id: gainsightId, applicationId });
			retries++;
			if (retries < maxRetries) {
				rgEvent(`Retrying in ${retryDelay / 1000} seconds`, {
					id: gainsightId,
					applicationId,
				});
				setTimeout(retryOperation, retryDelay);
			}
		}
	};

	retryOperation();
};

export const isGainsightAllowed = async (customerKey: string) => {
	const gainsightEnabled =
		(await getConfigurationOption(ConfigPropertyKey.EnableGainsight)).toLowerCase() === "true";
	const gainsightExclude = (await getConfigurationOption(ConfigPropertyKey.GainsightExclude))
		.toLowerCase()
		.split(",");
	return gainsightEnabled && !gainsightExclude.includes(customerKey.toLowerCase());
};

export const useGainsightLogin = (
	authenticationStatus: "loading" | "private" | "public" | null,
) => {
	useEffect(() => {
		const customerKey = getCustomerKey().toLowerCase();
		isGainsightAllowed(customerKey).then((isAllowed) => {
			if (
				isAllowed &&
				(authenticationStatus === "private" || authenticationStatus === "public")
			) {
				const customerKey = getCustomerKey().toLowerCase();
				const applicationId = window.localStorage.getItem("raygun4js-userid");
				getAuthUser().then((user) => {
					const profile = user?.profile as IdTokenClaims;
					if (applicationId) {
						gainsightLogin(
							profile?.gainsightId ?? applicationId,
							applicationId,
							customerKey,
							Boolean(profile?.gainsightId),
						);
					}
				});
			}
		});
	}, [authenticationStatus]);
};
