import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import moment from "moment";
import { ActionComplete } from "../ActionComplete";
import { ConfirmationModal, FormModal } from "../../../../components/modals";
import { DefaultActionsService } from "../../../../../services/actions/implementations/default";
import { DefaultAttachmentService } from "../../../../../services/attachment";
import { ErrorViewer } from "../../../../components/ErrorViewer/ErrorViewer.component";
import { fileNameToPost } from "../../../../../helpers/FileHelper";
import { getDateFormat } from "../../../../../helpers/DateTimeInputHelper";
import { IllustratedThemedModal } from "../../../../components/modals/IllustratedThemedModal";
import { Loading } from "../../../../components/Loading";
import { ReactComponent as ErrorIllustration } from "../../../../assets/svg/illustrations/falling.svg";
import { rgLog } from "../../../../../services/log";
import { switchMatchingLanguageCode } from "../../../../../helpers/LanguageCodeHelper";
import { useGetActionQuery } from "../../hooks/useGetActionQuery";
import { useManageActionAttachments } from "../../../../utilities/UseManageActionAttachments";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { usePreventBrowserReload } from "../../../../utilities/UsePreventBrowserReload";
import type { HistoryLocationState } from "../../../../../types/History";

interface Props {
	refetch: () => void;
}

export const ActionCompleteModal = ({ refetch }: Props) => {
	const { t, i18n } = useTranslation();
	const { actionId, customerKey, portalKey } = useParams<{
		actionId: string;
		customerKey: string;
		portalKey: string;
	}>();
	const portalPath = usePortalPath();
	const language = switchMatchingLanguageCode(i18n.language);
	const history = useHistory<HistoryLocationState>();
	const match = useRouteMatch("/:customerKey/p/:portalKey/actions/complete/:actionId");
	const { handleSubmit, formState, ...methods } = useForm({
		mode: "onBlur",
		criteriaMode: "all",
	});

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmitError, setIsSubmitError] = useState(false);
	const [isConfirmationModalActive, setIsConfirmationModalActive] = useState(false);

	const {
		isLoading,
		isError,
		data: action,
		error,
	} = useGetActionQuery(actionId, !!match, language);

	const { attachmentsToAdd, attachmentsToRemove, ...attachmentsProps } =
		useManageActionAttachments((action && action.Attachments) || []);

	const hasStateChanged = useMemo(
		() => formState.isDirty || attachmentsToAdd.length > 0 || attachmentsToRemove.length > 0,
		[attachmentsToAdd.length, attachmentsToRemove.length, formState.isDirty],
	);

	usePreventBrowserReload(hasStateChanged);

	const handleRedirect = useCallback(
		(isSubmit = false) => {
			if (isSubmit) {
				history.push(`${portalPath}actions`);
			} else if (history.location.state) {
				history.push(
					history.location.state.previousPath
						? history.location.state.previousPath
						: `${portalPath}actions`,
				);
			} else {
				history.push(`${portalPath}actions`);
			}
		},
		[history, portalPath],
	);

	const onCancel = () => {
		if (hasStateChanged) {
			setIsConfirmationModalActive(true);
		} else {
			handleRedirect();
		}
	};

	const onOk = useCallback(() => {
		handleSubmit((data) => {
			const attachmentsService = new DefaultAttachmentService({
				subdomain: "ActionAttachments",
			});
			const service = new DefaultActionsService({ subdomain: "Action" });
			const completeDate = moment(data.CompleteDate, getDateFormat(), true).utc(true);
			const dateToSend = completeDate.isValid()
				? completeDate.toISOString()
				: moment().utc(true).toISOString();
			const actionToPut: any = {
				...data,
				ActionCategory: action && action.ActionCategory,
				CompleteDate: dateToSend,
				CompletePercent: 100,
				Priority: action && action.Priority,
			};
			if (attachmentsToRemove.length) {
				actionToPut.RemoveAttachments = attachmentsToRemove;
			}
			if (attachmentsToAdd.length) {
				actionToPut.AddAttachments = attachmentsToAdd.map((attachment) => ({
					FileName: fileNameToPost(attachment.Identifier, attachment.FileName),
					FileDescription: "",
				}));
			}
			setIsSubmitting(true);
			attachmentsService
				.postActionAttachments(attachmentsToAdd)
				.then(() => {
					const language = switchMatchingLanguageCode(i18n.language);
					return service.putAction(
						actionId,
						customerKey,
						portalKey,
						actionToPut,
						language,
					);
				})
				.then(() => {
					setIsSubmitting(false);
					refetch();
					handleRedirect(true);
				})
				.catch((error) => {
					setIsSubmitting(false);
					setIsSubmitError(true);
					rgLog("send", error);
				});
		})();
	}, [
		action,
		actionId,
		attachmentsToAdd,
		attachmentsToRemove,
		customerKey,
		portalKey,
		handleSubmit,
		handleRedirect,
		refetch,
	]);

	return (
		<>
			<FormModal
				cancelText={t("global:cancel")}
				large
				okText={t("display:myTasks.labelCompleteTask")}
				onCancel={onCancel}
				onOk={onOk}
				show={!!match}
				title={t("display:myTasks.labelCompleteTask")}
				withHistory={false}
			>
				{isLoading && <div>{t("display:labelLoading")}</div>}

				{isError && (
					<>
						<div>{t("display:labelError")}</div>
						<ErrorViewer error={error} />
					</>
				)}

				{action && !isLoading && !isError ? (
					<FormProvider formState={formState} handleSubmit={handleSubmit} {...methods}>
						<ActionComplete action={action} {...attachmentsProps} />
					</FormProvider>
				) : null}
			</FormModal>

			{isSubmitError && (
				<IllustratedThemedModal
					cancelText={t("global:ok")}
					onCancel={() => setIsSubmitError(false)}
					show={isSubmitError}
					text={t("error:failedToUpdateTheTask")}
					withHistory={false}
				>
					<ErrorIllustration />
				</IllustratedThemedModal>
			)}

			{isSubmitting && <Loading withHistory={false} />}

			<ConfirmationModal
				onCancel={() => setIsConfirmationModalActive(false)}
				onConfirm={() => handleRedirect()}
				show={isConfirmationModalActive}
				text={t("display:labelAreYouSure")}
			/>
		</>
	);
};
