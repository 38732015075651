import type { Portal } from "../../../../../models/portal";
import "./PortalHeader.styles.scss";

interface Props {
	portal: Portal;
	caption?: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PortalHeader = ({ portal, caption }: Props) => {
	return (
		<header className="she-portal-header">
			{portal.notification && portal.notification.title && (
				<h2>{portal.notification.title}</h2>
			)}
			{portal.notification && portal.notification.description && (
				<div
					className="she-portal-description"
					dangerouslySetInnerHTML={{
						__html: (portal.notification.description || "").replace(
							/<a href=/g,
							'<a target="_blank" href=',
						),
					}}
				/>
			)}
		</header>
	);
};
