import { useState, useEffect, useCallback, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Media from "react-media";
import { useTranslation, Trans } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { SectionType, QuestionnaireProgressState } from "../../../models/questionnaire";
import { loadPortal, loadOrgUnits } from "../../../state";
import {
	createQuestionnaire,
	cancelQuestionnaire,
	submitQuestionnaire,
	updateQuestionnaireStatus,
	loadQuestionnaire,
} from "../../../state/components/questionnaire";
import { PortalViewType } from "../../../models/portal";
import { ReactComponent as CancelIllustration } from "../../assets/svg/illustrations/cancel.svg";
import { ReactComponent as SubmitSuccessIllustration } from "../../assets/svg/illustrations/success.svg";
import { ReactComponent as ErrorIllustration } from "../../assets/svg/illustrations/caution.svg";
import { IllustratedThemedModal } from "../../components/modals/IllustratedThemedModal";
import { APIHealthContext } from "../../../services/health/implementations/healthContext/healthContext";
import { OnlineStatus } from "../../../services/health/implementations/healthMonitor";
import { isBrowserIE } from "../../../helpers/ieHelper";
import { Loading } from "../../components/Loading";
import { ScrollRootToTop } from "../../components/ScrollRootToTop";
import { ConfirmationModal } from "../../components/modals";
import { usePortalPath } from "../../../helpers/UsePortalPath";
import {
	cancelRecordAttachments,
	loadRecordAttachments,
} from "../../../state/components/attachment";
import { FormLayout } from "../../layouts/FormLayout";
import { useDetailedSubmitError } from "../../utilities/UseDetailedSubmitError";
import { AttachmentDescriptionModal } from "../../components/attachments/AttachmentDescriptionModal";
import { CommonErrorNotification } from "../../components/error/CommonErrorNotification";
import { rgEvent } from "../../../services/log/log";
import { GuidanceSection } from "./Components/sections/GuidanceSection";
import { AttachmentSection } from "./Components/sections/AttachmentSection";
import { QuestionnaireSection } from "./Components/sections/QuestionnaireSection";
import { ButtonBar } from "./Components/ButtonBar";
import { SectionMobileNav } from "./Components/nav/SectionMobileNav";
import { SectionNav } from "./Components/nav/SectionNav";
import { QuestionnaireHeader } from "./Components/Header";
import { LoadingMock } from "./Components/LoadingMock";
import { AlertMessages } from "./Components/AlertMessages";
import { FileSizeErrorModal } from "./Components/FileSizeErrorModal";
import scssUtils from "./QuestionnairePage.module.scss";
import type { OrgUnit } from "../../../models/orgUnit";
import type { Portal } from "../../../models/portal";
import type { Questionnaire, Section, Status } from "../../../models/questionnaire";
import type { State } from "../../../state";
import "./QuestionnairePage.styles.scss";

export const QuestionnairePage = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { customerKey, portalKey, questionnaireName, questionnaireRecordId, formsTab } =
		useParams<{
			customerKey: string;
			portalKey: string;
			questionnaireName: string;
			questionnaireRecordId: string;
			formsTab: PortalViewType | undefined;
		}>();
	const health = useContext(APIHealthContext);
	const portal = useSelector<State, Portal | undefined>((state) =>
		state.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);
	const questionnaire = useSelector<State, Questionnaire | undefined>(
		(state) => state.questionnaire.questionnaire,
	);
	const orgUnits = useSelector<State, OrgUnit[]>((s) => s.orgUnit.orgUnits);
	const sections = useSelector<State, Section[] | undefined>(
		(state) => state.questionnaire.sections,
	);

	const status = useSelector<State, Status | undefined>((state) => state.questionnaire.status);
	const isDirty = useSelector<State, boolean | undefined>((state) => state.questionnaire.isDirty);
	const dispatch = useDispatch();
	const [sectionIndex, setSectionIndex] = useState(0);
	const [isOpen, setIsOpen] = useState(false);
	const [displayValidationError, setDisplayValidationError] = useState(false);
	const [loadOrgUnit, setLoadOrgUnit] = useState(true);
	const [createdQuestionnaire, setCreatedQuestionnaire] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const portalPath = usePortalPath();
	const [readableError, setReadableError] = useState<string | undefined>();
	const [refocusSection, setRefocusSection] = useState(false);

	const sectionHeadingRef = useRef<HTMLHeadingElement>(null);

	const visibleSections: Section[] = sections ? sections.filter((s) => s.isVisible === true) : [];
	const areYouSureMessage = t("display:labelAreYouSure");
	const isSubmitted = !!(
		status &&
		(status.isSubmitSuccess === true ||
			status.questionnaireState === QuestionnaireProgressState.Completed)
	);
	const isSubmitError = !!(
		status &&
		status.isSubmitSuccess === false &&
		status.errors.length > 0
	);
	const offlineEnabled = isBrowserIE() === false;

	const forceLoginModal = useSelector<State, boolean>((state) => state.auth.forcingLoginModal);

	const eventListener = useCallback(
		(e: BeforeUnloadEvent) => {
			if (isDirty) {
				e.preventDefault();
				e.returnValue = "";
			}
		},
		[isDirty],
	);

	const detailedSubmitError = useDetailedSubmitError();

	useEffect(() => {
		if (status && status.errors && status.errors.length > 0) {
			setReadableError(detailedSubmitError(status.errors));
		}
	}, [status, detailedSubmitError]);

	const goBackToPortal = useCallback(
		(saveQuestionnaire: boolean, destination: PortalViewType) => {
			setIsOpen(false);
			setShowConfirmationModal(false);
			dispatch(cancelRecordAttachments());
			rgEvent("Form saved to In progress", {
				questionnaireId: questionnaire ? questionnaire.id : null,
			});
			dispatch(
				cancelQuestionnaire(
					saveQuestionnaire,
					questionnaire ? questionnaire.id : undefined,
				),
			);
			history.push(
				`${portalPath}${destination}`,
				(destination === PortalViewType.InProgress ||
					destination === PortalViewType.Queued) &&
					questionnaire
					? { highlightRecord: questionnaire.id }
					: { highlightRecord: questionnaireName },
			);
		},
		[history, portalPath, dispatch, questionnaire, questionnaireName],
	);

	useEffect(() => {
		if (!forceLoginModal) {
			window.addEventListener("beforeunload", eventListener);
		} else {
			window.removeEventListener("beforeunload", eventListener);
		}
		return () => window.removeEventListener("beforeunload", eventListener);
	}, [forceLoginModal, eventListener]);

	useEffect(() => {
		if (status && status.displayValidationMessage) {
			setDisplayValidationError(true);
		}
	}, [status]);

	useEffect(() => {
		if (!portal) {
			dispatch(loadPortal(portalKey.toLowerCase()));
		}

		if (questionnaireRecordId) {
			if (!questionnaire && portal) {
				rgEvent("Form Loaded from In progress", { questionnaireId: questionnaireRecordId });
				dispatch(loadRecordAttachments(questionnaireRecordId));
				dispatch(loadQuestionnaire(questionnaireRecordId));
			}
		} else if (!questionnaire && portal && questionnaireName && !createdQuestionnaire) {
			setCreatedQuestionnaire(true);
			rgEvent("Form Created", { questionnaireId: questionnaireRecordId });
			dispatch(createQuestionnaire(questionnaireName as any as number, portal, history));
		}

		if (portal && questionnaire && loadOrgUnit) {
			setLoadOrgUnit(false);
			dispatch(loadOrgUnits(portal.maskedOrgUnitId || 0, customerKey.toLowerCase()));
		}
	}, [
		portal,
		questionnaire,
		questionnaireName,
		orgUnits,
		customerKey,
		history,
		portalKey,
		dispatch,
		loadOrgUnit,
		health,
		questionnaireRecordId,
		createdQuestionnaire,
	]);

	useEffect(() => {
		if (refocusSection) {
			sectionHeadingRef.current?.focus();
			setRefocusSection(false);
		}
	}, [refocusSection]);

	// This will be replaced with a loading gate in the future
	const isLoading = !questionnaire || !portal || !orgUnits.length;

	const closeButtonPressed = () => {
		if (isDirty === true) {
			if (offlineEnabled) {
				setShowConfirmationModal(true);
			} else {
				setIsOpen(true);
			}
		} else {
			// We need to check if the questionnaire was newly created or loaded from the inprogress section. If from inprogress then always send true so that record is not removed from state.
			const saveQuestionnaire = createdQuestionnaire === false;
			goBackToPortal(
				saveQuestionnaire,
				saveQuestionnaire
					? status &&
					  status.questionnaireState === QuestionnaireProgressState.UploadFailed
						? PortalViewType.Queued
						: PortalViewType.InProgress
					: PortalViewType.Home,
			);
		}
	};

	const getSectionsIndices = () => {
		return visibleSections.map(({ orderIndex }) => orderIndex);
	};

	const changeSection = (newSectionIndex: number, refocusSection = false) => {
		setSectionIndex(newSectionIndex);
		setRefocusSection(refocusSection);
	};

	const nextSection = () => {
		const indices = getSectionsIndices();
		const currPosition: number | undefined = indices.indexOf(sectionIndex);
		if (currPosition >= 0 && currPosition < indices.length - 1) {
			setSectionIndex(indices[currPosition + 1]);
			setRefocusSection(true);
		}
	};

	const previousSection = () => {
		const indices = getSectionsIndices();
		const currPosition: number | undefined = indices.indexOf(sectionIndex);
		if (currPosition > 0) {
			setSectionIndex(indices[currPosition - 1]);
			setRefocusSection(true);
		}
	};

	const submitRecord = () => {
		rgEvent("Submit requested", { questionnaireId: questionnaire && questionnaire.id });
		questionnaire && portal && dispatch(submitQuestionnaire(questionnaire.id, portal));
	};

	const currentSection: Section | undefined = visibleSections.find(
		(s) => s.orderIndex === sectionIndex,
	);

	// Replace template path with questionnare url when possible
	useEffect(() => {
		if (offlineEnabled && history && formsTab === PortalViewType.Forms && questionnaire) {
			const newPath = `${portalPath}${PortalViewType.InProgress}/${questionnaire.id}`;
			history.replace(newPath);
		}
	}, [questionnaireRecordId, questionnaire, formsTab, history, offlineEnabled, portalPath]);

	return (
		<>
			<ScrollRootToTop observe={displayValidationError} />
			<AlertMessages />
			{!isLoading && questionnaire && (
				<FormLayout
					header={
						<QuestionnaireHeader
							backButtonPressed={closeButtonPressed}
							title={questionnaire.name}
						/>
					}
					nav={
						<Media query={`(min-width: ${scssUtils.breakpointS})`}>
							{(matches) =>
								matches ? (
									<SectionNav
										changeSection={(index: number) => {
											changeSection(index);
										}}
										sectionIndex={sectionIndex}
									/>
								) : (
									sections &&
									sections.length > 1 && (
										<SectionMobileNav
											changeSection={(index: number) => {
												changeSection(index);
											}}
											sectionIndex={sectionIndex}
										/>
									)
								)
							}
						</Media>
					}
				>
					<div className="she-questionnaire-page-column">
						<div className="she-questionnaire-page-container">
							{currentSection && currentSection.type === SectionType.Guidance && (
								<GuidanceSection ref={sectionHeadingRef} />
							)}

							{currentSection && currentSection.type === SectionType.Attachments && (
								<AttachmentSection
									questionnaireId={questionnaire.id}
									ref={sectionHeadingRef}
								/>
							)}

							{currentSection && currentSection.type !== SectionType.Attachments && (
								<QuestionnaireSection
									ref={sectionHeadingRef}
									sectionId={currentSection.id}
								/>
							)}
						</div>

						<nav className="she-form-controls">
							<ButtonBar
								allSectionsIndices={getSectionsIndices()}
								backButtonPressed={() => {
									previousSection();
								}}
								cancelButtonPressed={closeButtonPressed}
								currentSectionIndex={sectionIndex}
								isSubmitDisabled={
									status && status.isSubmittingInProgress != null
										? status.isSubmittingInProgress
										: false
								}
								nextButtonPressed={() => {
									nextSection();
								}}
								submitButtonPressed={() => {
									submitRecord();
								}}
							/>
						</nav>
					</div>
					<IllustratedThemedModal
						cancelText={t("display:buttonNoReturn")}
						confirmText={t("display:buttonYesCancel")}
						onCancel={() => setIsOpen(false)}
						onConfirm={() =>
							goBackToPortal(
								true,
								status &&
									status.questionnaireState ===
										QuestionnaireProgressState.UploadFailed
									? PortalViewType.Queued
									: PortalViewType.Home,
							)
						}
						show={isOpen}
						text={areYouSureMessage}
					>
						<CancelIllustration />
					</IllustratedThemedModal>
					<IllustratedThemedModal
						cancelText={t("global:ok")}
						onCancel={() => {
							goBackToPortal(
								health !== OnlineStatus.Available,
								health === OnlineStatus.Available
									? PortalViewType.Home
									: PortalViewType.Queued,
							);
						}}
						show={isSubmitted}
						showEntryAnimation={false}
						text={
							health === OnlineStatus.Available
								? t("display:labelSubmitSuccessMessage")
								: t("display:labelSaveSuccessMessage")
						}
					>
						<SubmitSuccessIllustration />
					</IllustratedThemedModal>
					<IllustratedThemedModal
						cancelText={t("global:ok")}
						onCancel={() => {
							if (status && status.firstSectionWithErrors !== null) {
								const section =
									sections &&
									sections.find((s) => s.id === status.firstSectionWithErrors);
								if (section) {
									changeSection(section.orderIndex, true);
								}
							}
							setDisplayValidationError(false);
						}}
						show={displayValidationError}
						text={t("validation:validationErrorsOccurred")}
					>
						<ErrorIllustration />
					</IllustratedThemedModal>
					<IllustratedThemedModal
						cancelText={t("global:ok")}
						onCancel={() => {
							dispatch(updateQuestionnaireStatus(false, undefined, []));
						}}
						show={isSubmitError}
						showEntryAnimation={false}
						text={readableError}
					>
						<ErrorIllustration />
					</IllustratedThemedModal>
					<ConfirmationModal
						cancelBtnIsPrimary={false}
						cancelText={t("global:back")}
						centredBody
						confirmBtnIsDanger={false}
						confirmBtnIsPrimary
						confirmText={t("display:buttonSaveAndClose")}
						onCancel={() => {
							setShowConfirmationModal(false);
						}}
						onConfirm={() => {
							goBackToPortal(
								true,
								status &&
									status.questionnaireState ===
										QuestionnaireProgressState.UploadFailed
									? PortalViewType.Queued
									: PortalViewType.InProgress,
							);
						}}
						show={showConfirmationModal}
						text={t("display:labelSaveAndCloseTitle")}
						textExtraClass="she-components-modal-body-title"
					>
						<Trans i18nKey="display:labelSaveAndClose">
							stringPart1 <strong>stringPart2</strong>stringPart3
						</Trans>
					</ConfirmationModal>
				</FormLayout>
			)}
			{status && status.isSubmittingInProgress && (
				<Loading show text={t("display:labelSubmittingPleaseWait")} />
			)}
			{isLoading && <LoadingMock />}
			<Loading show={isLoading} showEntryAnimation={false} />
			<AttachmentDescriptionModal />
			{!isLoading && (
				<>
					<CommonErrorNotification
						handleOnce
						message={t("error:quotaExceededOther")}
						reason="otherQuotaError"
					/>
					<CommonErrorNotification
						handleOnce={false}
						message={t("error:quotaExceededAttachments")}
						reason="attachmentQuotaError"
					/>
				</>
			)}
			<FileSizeErrorModal />
		</>
	);
};
