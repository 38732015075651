import { SectionType } from "../../../../models/questionnaire";
import type { BranchingService, EvaluationService } from "../../interface";
import type { Field } from "../../../../models/fields/Field";
import type { Section } from "../../../../models/questionnaire/Section";
import type { BranchCondition } from "../../../../models/questionnaire/BranchCondition";
import type { BranchTriggeringInfo } from "../../../../models/branching/branchTriggeringInfo";
import type { BranchInfo } from "../../../../models/branching/branchInfo";

export class DefaultBranchingService implements BranchingService {
	public initialiseQuestionnaire(
		branchInfo: BranchInfo,
		evaluationService: EvaluationService,
	): BranchInfo {
		const evaluatedConditions = new Map();
		if (branchInfo.conditions.length) {
			branchInfo.sections
				.filter((s) => s.id !== 0 && s.type === SectionType.iQ)
				.forEach((s) => {
					this.processElement(
						s.id,
						"Section",
						branchInfo,
						evaluationService,
						evaluatedConditions,
					);
				});
		}

		return branchInfo;
	}

	public getBranchingChanges(
		triggeringInfo: BranchTriggeringInfo,
		evaluationService: EvaluationService,
	): BranchInfo {
		const evaluatedConditions = new Map();
		const branchInfo: BranchInfo = {
			sections: triggeringInfo.sections,
			fields: triggeringInfo.fields,
			conditions: triggeringInfo.conditions,
		};
		const allNestedBranchConditions = triggeringInfo.conditions.filter(
			(condition) => condition.questionTemplateId === triggeringInfo.fieldUpdated.id,
		);

		allNestedBranchConditions.forEach((c) => {
			this.processElement(
				c.id,
				"Condition",
				branchInfo,
				evaluationService,
				evaluatedConditions,
			);
		});

		return branchInfo;
	}

	private processElement(
		elementID: number,
		elementType: string,
		branchInfo: BranchInfo,
		evaluationService: EvaluationService,
		evaluatedConditions: Map<number, boolean>,
	) {
		switch (elementType) {
			case "Section": {
				const section = branchInfo.sections.find((s) => s.id === elementID);
				if (section) {
					const parentConditions = branchInfo.conditions.filter((c) =>
						c.shownSectionsIds.includes(section.id),
					);
					this.processElementSection(
						section,
						parentConditions,
						branchInfo,
						evaluationService,
						evaluatedConditions,
					);
				}
				break;
			}
			case "Condition": {
				const condition = branchInfo.conditions.find((c) => c.id === elementID);
				if (condition) {
					const parentField = branchInfo.fields.find(
						(f) => f.id === condition.questionTemplateId,
					);
					if (parentField) {
						this.processElementCondition(
							condition,
							parentField,
							branchInfo,
							evaluationService,
							evaluatedConditions,
						);
					}
				}
				break;
			}
			default: {
				const field = branchInfo.fields.find((f) => f.id === elementID && f.parentType);
				if (field) {
					let parentCondition: BranchCondition | undefined;
					let parentSection: Section | undefined;

					if (field.parentType === "Condition") {
						parentCondition = branchInfo.conditions.find(
							(c) => c.id === field.parentId,
						);
					} else if (field.parentType === "Section") {
						parentSection = branchInfo.sections.find((s) => s.id === field.parentId);
					}
					this.processElementField(
						field,
						parentSection,
						parentCondition,
						branchInfo,
						evaluationService,
						evaluatedConditions,
					);
				}
				break;
			}
		}
	}

	private processElementCondition(
		condition: BranchCondition,
		parentfield: Field,
		branchInfo: BranchInfo,
		evaluationService: EvaluationService,
		evaluatedConditions: Map<number, boolean>,
	) {
		condition.istriggered =
			!parentfield.hidden &&
			!parentfield.isNotApplicable &&
			evaluationService.evaluateCondition(parentfield, condition);

		// If condition was not evaluated previously or condition's trigger value changed we need to process tree below the condition again.
		if (evaluatedConditions.get(condition.id) !== condition.istriggered) {
			evaluatedConditions.set(condition.id, condition.istriggered);

			// Nested Fields
			const nestedFields = branchInfo.fields.filter(
				(f) =>
					f.parentId &&
					f.parentType &&
					f.parentType === "Condition" &&
					f.parentId === condition.id,
			);
			nestedFields.forEach((f) => {
				this.processElement(
					f.id,
					"Field",
					branchInfo,
					evaluationService,
					evaluatedConditions,
				);
			});

			// Linked Sections
			condition.shownSectionsIds.forEach((s) => {
				this.processElement(
					s,
					"Section",
					branchInfo,
					evaluationService,
					evaluatedConditions,
				);
			});
		}
	}

	// the below uses of << Type | undefined >> is required to keep the parameters in the desired order
	// this is because << Type? >> makes it optional when called and optional parameters must appear after required parameters
	private processElementField(
		field: Field,
		parentSection: Section | undefined,
		parentCondition: BranchCondition | undefined,
		branchInfo: BranchInfo,
		evaluationService: EvaluationService,
		evaluatedConditions: Map<number, boolean>,
	) {
		let parentVisible = false;
		if (parentSection) {
			parentVisible = parentSection.isVisible;
		} else if (parentCondition) {
			parentVisible = parentCondition.istriggered;
		}

		field.hidden = !parentVisible;

		if (field.hidden) {
			field.validationMessage = [];
		}

		// Nested Conditions
		const nestedConditions = branchInfo.conditions.filter(
			(c) => c.questionTemplateId === field.id,
		);
		nestedConditions.forEach((c) => {
			this.processElement(
				c.id,
				"Condition",
				branchInfo,
				evaluationService,
				evaluatedConditions,
			);
		});
	}

	private processElementSection(
		section: Section,
		parentConditions: BranchCondition[],
		branchInfo: BranchInfo,
		evaluationService: EvaluationService,
		evaluatedConditions: Map<number, boolean>,
	) {
		// this length check is required to ensure changes are not performed to any sections that don't have parent conditions
		if (parentConditions.length) {
			const parentConditionsTriggered = parentConditions.filter((c) => c.istriggered);
			section.isVisible = parentConditionsTriggered.length > 0;
		}

		// Nested Fields (children only)
		const nestedFields = branchInfo.fields.filter(
			(f) =>
				f.parentType &&
				f.parentType === "Section" &&
				f.sectionId &&
				f.sectionId === section.id,
		);
		nestedFields.forEach((f) => {
			this.processElement(f.id, "Field", branchInfo, evaluationService, evaluatedConditions);
		});
	}
}
