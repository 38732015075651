import { useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { WaitingServiceWorkerContext } from "../WaitingServiceWorkerContext";
import { Loading } from "../Loading";
import { NotificationModal } from "../modals";
import { APIHealthContext } from "../../../services/health/implementations/healthContext/healthContext";
import { OnlineStatus } from "../../../services/health/implementations/healthMonitor";
import { Button } from "../input/Button";
import { rgLog } from "../../../services/log";
import packageJson from "../../../../package.json";

enum UpdateState {
	Initial,
	Checking,
	RefreshRequired,
	UpdateWaiting,
	Message,
}

export const ServiceWorkerUpdateNotification = () => {
	const { t } = useTranslation();

	const [isInstalling, setIsInstalling] = useState(false);
	const [updateState, setUpdateState] = useState(UpdateState.Initial);
	const [updateMessage, setUpdateMessage] = useState<string | undefined>();
	const waitingInContextActivated = useContext(WaitingServiceWorkerContext);
	const onlineStatus = useContext(APIHealthContext);

	const activateUpdate = useCallback(async () => {
		if (navigator.serviceWorker) {
			try {
				const registrations = await navigator.serviceWorker.getRegistrations();
				const waitingRegistration = registrations.find((r) => r.waiting !== null);
				const waitingServiceWorker = waitingRegistration && waitingRegistration.waiting;

				if (waitingServiceWorker) {
					waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });

					if (waitingServiceWorker.onstatechange !== null) {
						setIsInstalling(true);
					}
				} else {
					setIsInstalling(false);
				}
			} catch (error) {
				setIsInstalling(false);
				setUpdateState(UpdateState.Message);
				rgLog("send", error);
				setUpdateMessage(t("error:applicationUpdateFailedCannotInstallError", { error }));
			}
		}
	}, [t]);

	// Reload if worker is ready
	useEffect(() => {
		if (waitingInContextActivated) {
			setIsInstalling(true);
			window.location.reload();
		} else {
			setIsInstalling(false);
		}
	}, [waitingInContextActivated]);

	const handleSWMessage = (event: MessageEvent) => {
		if (event.data?.type === "REPLY_VERSION") {
			if (event.data.version !== packageJson.version) {
				setUpdateState(UpdateState.RefreshRequired);
				setUpdateMessage(t("display:updateAvailable"));
			} else {
				setUpdateState(UpdateState.Message);
				setUpdateMessage(t("display:updateNotAvailable"));
			}
		}
	};

	// Call update on first render
	useEffect(() => {
		if (navigator.serviceWorker) {
			navigator.serviceWorker
				.getRegistration()
				.then((r) => {
					r && r.update();
				})
				.catch((e) => {
					rgLog("send", e);
				});

			navigator.serviceWorker.addEventListener("message", handleSWMessage);

			return () => {
				navigator.serviceWorker.removeEventListener("message", handleSWMessage);
			};
		}
	}, []);

	// Force activate worker
	useEffect(() => {
		activateUpdate();
	}, [activateUpdate]);

	const onHandleUpdateCheck = useCallback(async () => {
		if (onlineStatus !== OnlineStatus.Available) {
			setUpdateState(UpdateState.Message);
			setUpdateMessage(t("error:applicationUpdateFailedDeviceOffline"));
			return;
		}

		if (navigator.serviceWorker) {
			setUpdateState(UpdateState.Checking);

			const registration = await navigator.serviceWorker.getRegistration();

			try {
				if (registration) {
					const updateResult = (await registration.update()) as unknown as Promise<
						ServiceWorkerRegistration | undefined
					>;

					if (updateResult && updateResult instanceof ServiceWorkerRegistration) {
						const nextWorker = updateResult.installing || updateResult.waiting;

						if (nextWorker) {
							setUpdateState(UpdateState.UpdateWaiting);
							setUpdateMessage(t("display:updateAvailable"));
						} else if (navigator.serviceWorker.controller) {
							navigator.serviceWorker.controller.postMessage({
								type: "GET_VERSION",
							});
						} else {
							setUpdateState(UpdateState.Message);
							setUpdateMessage(t("display:updateNotAvailable"));
						}
					} else {
						setUpdateState(UpdateState.Message);
						setUpdateMessage(t("error:applicationUpdateFailedCannotCheck"));
					}
				} else {
					setUpdateState(UpdateState.Message);
					setUpdateMessage(t("error:applicationUpdateFailedNotInstalled"));
				}
			} catch (error) {
				rgLog("send", error);
				setUpdateState(UpdateState.Message);
				setUpdateMessage(t("error:applicationUpdateFailedNotInstalledError", { error }));
			}
		} else {
			setUpdateState(UpdateState.Message);
			setUpdateMessage(t("error:applicationUpdateFailedNotInstalled"));
		}
	}, [onlineStatus, t]);

	const onHandleUpdate = useCallback(() => {
		setUpdateState(UpdateState.Initial);
		setIsInstalling(true);
		activateUpdate();
	}, [activateUpdate]);

	const onHandleRefreshRequired = () => {
		window.location.reload();
	};

	return (
		<>
			<div className="she-layout-flex-center">
				<Button onClick={onHandleUpdateCheck} variant="secondary">
					{t("display:labelCheckForNewVersion")}
				</Button>
			</div>
			<NotificationModal
				closeText={t("global:ok")}
				content={updateMessage}
				onClose={() => setUpdateState(UpdateState.Initial)}
				show={updateState === UpdateState.Message}
				showEntryAnimation={false}
			/>

			<NotificationModal
				closeText={t("global:ok")}
				content={updateMessage}
				onClose={onHandleUpdate}
				show={updateState === UpdateState.UpdateWaiting}
				showEntryAnimation={false}
			/>

			<NotificationModal
				closeText={t("global:ok")}
				content={updateMessage}
				onClose={onHandleRefreshRequired}
				show={updateState === UpdateState.RefreshRequired}
				showEntryAnimation={false}
			/>

			{updateState === UpdateState.Checking && (
				<Loading show text={t("display:labelCheckingForNewVersion")} withHistory={false} />
			)}
			<Loading show={isInstalling} text={t("display:labelNewVersion")} withHistory={false} />
		</>
	);
};
