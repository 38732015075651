import { useTranslation } from "react-i18next";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import styles from "./BooleanInput.module.scss";

interface Props {
	fieldGuid: string;
	fieldGroupName: string;
	value?: boolean;
	required: boolean;
	isNotApplicable: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClick: (value: boolean) => void;
}

export const BooleanInputPresentation = ({
	fieldGroupName,
	fieldGuid,
	isNotApplicable,
	onChange,
	onClick,
	required,
	value,
}: Props) => {
	const { t } = useTranslation();

	return (
		<AnimationWrapper>
			{!isNotApplicable && (
				<AnimationSlideFade key={fieldGuid}>
					<div
						aria-labelledby={`${fieldGuid}-label`}
						aria-required={required}
						className={styles.booleanGroup}
						data-testid="boolean-input"
						role="radiogroup"
					>
						<div className={`she-components-radio-group-button ${styles.booleanRadio}`}>
							<input
								checked={value === true}
								id={`${fieldGroupName}_yes`}
								name={fieldGroupName}
								onChange={onChange}
								onClick={() => onClick(true)}
								type="radio"
								value="true"
							/>
							<label htmlFor={`${fieldGroupName}_yes`}>{t("global:yes")}</label>
						</div>
						<div className={`she-components-radio-group-button ${styles.booleanRadio}`}>
							<input
								checked={value === false}
								id={`${fieldGroupName}_no`}
								name={fieldGroupName}
								onChange={onChange}
								onClick={() => onClick(false)}
								type="radio"
								value="false"
							/>
							<label htmlFor={`${fieldGroupName}_no`}>{t("global:no")}</label>
						</div>
					</div>
				</AnimationSlideFade>
			)}
		</AnimationWrapper>
	);
};
