import { useRef, useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import moment from "moment";
import { ReactComponent as Spots } from "../../../../assets/svg/navigation-menu-vertical.svg";
import { getBlobUrl } from "../../../../../helpers/BlobHelper";
import { PortalQuestionnaireThumbnail } from "../PortalQuestionnaireThumbnail";
import { useHighlight } from "../../../../../helpers/UseHighlight";
import { QuestionnaireProgressState } from "../../../../../models/questionnaire";
import { APIHealthContext } from "../../../../../services/health/implementations/healthContext/healthContext";
import { OnlineStatus } from "../../../../../services/health/implementations/healthMonitor";
import { rgEvent } from "../../../../../services/log/log";
import type { RecordStub } from "../../../../../models/portal";
import type { RouteComponentProps } from "react-router-dom";
import "./QueuedRecord.styles.scss";

interface Props extends RouteComponentProps {
	recordStub: RecordStub;
	dateFormat: string;
	onRecordSelected: (id: string) => void;
	onRecordSelectKeyboard: (id: string, key: string) => void;
	onRemoveRecord: (id: string, contextMenu: HTMLButtonElement | null) => void;
	onUploadRecord: (id: string, contextMenu: HTMLButtonElement | null) => void;
}

export const QueuedRecord = withRouter((props: Props) => {
	const { t } = useTranslation();
	const health = useContext(APIHealthContext);
	const menuBoxRef = useRef<HTMLDivElement>(null);
	const menuButtonRef = useRef<HTMLButtonElement>(null);
	const [displayOptions, setDisplayOptions] = useState<boolean>(false);

	const itemRef = useRef<HTMLDivElement>(null);
	const highlight = useHighlight(props.recordStub.highlight, itemRef);
	const classes = classnames(
		"she-questionnaire-record",
		highlight && "she-questionnaire-record--highlight",
	);

	const handleKeyDownOutside = () => {
		if (displayOptions === true) {
			setTimeout(() => {
				if (!menuBoxRef.current!.contains(document.activeElement)) {
					setDisplayOptions(false);
				}
			}, 1);
		}
	};

	const handleClickOutside = (e: any) => {
		if (displayOptions === true) {
			if (!menuBoxRef.current!.contains(e.target)) {
				setDisplayOptions(false);
			}
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside, false);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside, false);
		};
	});

	const removeButtonClicked = () => {
		rgEvent("Form Deleted from Queue", { questionnaireId: props.recordStub.id });
		props.onRemoveRecord(props.recordStub.id, menuButtonRef.current);
	};

	const uploadButtonClicked = () => {
		props.onUploadRecord(props.recordStub.id, menuButtonRef.current);
	};

	return (
		<li className={classes}>
			<div
				onClick={() => props.onRecordSelected(props.recordStub.id)}
				onKeyPress={(e) => {
					if (displayOptions === false) {
						props.onRecordSelectKeyboard(props.recordStub.id, e.key);
					}
				}}
				ref={itemRef}
				tabIndex={0}
			>
				<PortalQuestionnaireThumbnail
					imageUrl={
						props.recordStub.templateStub.image &&
						getBlobUrl(props.recordStub.templateStub.image)
					}
					module={props.recordStub.templateStub.module}
				/>
				<div className="she-questionnaire-record-body">
					<p className="she-questionnaire-record-body-title">{props.recordStub.name}</p>
					<p className="she-questionnaire-record-body-data">
						{`${t("display:labelDateCompleted")}: ${moment(
							props.recordStub.dateCompleted,
						).format(props.dateFormat)}`}
					</p>
					{props.recordStub.state === QuestionnaireProgressState.UploadFailed && (
						<p className="she-queued-record-submission-failure">
							{t("display:labelSubmissionFailed")}
						</p>
					)}
				</div>
				<div className="she-questionnaire-record-options-menu">
					<button
						aria-label={t("display:buttonOptions")}
						className="she-icon"
						onClick={(e) => {
							setDisplayOptions(true);
							e.stopPropagation();
						}}
						onKeyPress={(e) => {
							setDisplayOptions(true);
							e.stopPropagation();
						}}
						ref={menuButtonRef}
						type="button"
					>
						<Spots />
					</button>
				</div>
				<div
					className={`she-portal-record-options-wrapper ${
						displayOptions ? "she-portal-record-thumbnail-showOptions" : ""
					} `}
					onBlur={handleKeyDownOutside}
					onClick={(e) => {
						e.preventDefault();
					}}
					ref={menuBoxRef}
				>
					<div className="she-portal-record-options">
						{props.recordStub.state &&
							props.recordStub.state === QuestionnaireProgressState.UploadFailed && (
								<div className="she-portal-record-option">
									<button
										onClick={() => {
											props.onRecordSelected(props.recordStub.id);
										}}
										type="button"
									>
										<div>
											<span>{t("display:buttonEdit")}</span>
										</div>
									</button>
								</div>
							)}
						{props.recordStub.state &&
							props.recordStub.state === QuestionnaireProgressState.UploadFailed &&
							health === OnlineStatus.Available && (
								<div className="she-portal-record-option">
									<button
										onClick={(e) => {
											e.stopPropagation();
											uploadButtonClicked();
											setDisplayOptions(false);
										}}
										type="button"
									>
										<div>
											<span>{t("display:buttonRetry")}</span>
										</div>
									</button>
								</div>
							)}
						{props.recordStub.state &&
							props.recordStub.state !== QuestionnaireProgressState.UploadFailed &&
							health === OnlineStatus.Available && (
								<div className="she-portal-record-option">
									<button
										onClick={(e) => {
											e.stopPropagation();
											uploadButtonClicked();
											setDisplayOptions(false);
										}}
										type="button"
									>
										<div>
											<span>{t("display:buttonUpload")}</span>
										</div>
									</button>
								</div>
							)}
						<div className="she-portal-record-option">
							<button
								// eslint-disable-next-line jsx-a11y/no-autofocus
								autoFocus
								onClick={(e) => {
									e.stopPropagation();
									removeButtonClicked();
									setDisplayOptions(false);
								}}
								tabIndex={0}
								type="button"
							>
								<div>
									<span>{t("display:buttonDelete")}</span>
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</li>
	);
});
