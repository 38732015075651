import moment from "moment";
import { v4 as uuid } from "uuid";
import { AttachmentParentType } from "../../models/attachments/Attachment";
import {
	FieldType,
	LocalFieldIds,
	SelectFieldType,
	SubmoduleType,
} from "../../models/questionnaire";
import {
	combineDateString,
	combineTimeString,
	formatOutboundDateTime,
	getDateTimeFormat,
	getTimeFormat,
} from "../../helpers/DateTimeInputHelper";
import { formatInput } from "../../ui/components/input/GeoPositionInput/GeoPositionInput.container";
import { trimBase64String } from "../../helpers/SignatureHelper";
import type { Attachment } from "../../models/attachments/Attachment";
import type {
	BranchCondition,
	Section,
	SubModule,
	SubModuleRecord,
	SubModuleRecordValue,
} from "../../models/questionnaire";
import type { AuditField } from "../../models/fields/AuditField";
import type { BehaviouralField } from "../../models/fields/BehaviouralField";
import type { BooleanField } from "../../models/fields/BooleanField";
import type { EntitySelectField } from "../../models/fields/EntitySelectField";
import type { EnumSelectField } from "../../models/fields/EnumSelectField";
import type { Field } from "../../models/fields/Field";
import type { IAssignmentDTO } from "../../models/assignment/assignment";
import type { GeoPositionField } from "../../models/fields/GeoPositionField";
import type { IQDateField } from "../../models/fields/IQDateField";
import type { IQDateTimeField } from "../../models/fields/IQDateTimeField";
import type { IQSignatureField } from "../../models/fields/IQSignatureField";
import type { MultiSelectField } from "../../models/fields/MultiSelectField";
import type { PPECategoryValue } from "../../models/fields/PPEField";
import type { SingleSelectField } from "../../models/fields/SingleSelectField";
import type { TimeField } from "../../models/fields/TimeField";

export const mapPortalFieldToAnswerDto = (field: Field): { MapLineId: number; StoreValue: any } => {
	const answerField = {
		MapLineId: field.id,
	};

	const fieldType =
		field.type === FieldType.Indirect
			? field.originalField.type === FieldType.OptionalSelect
				? field.originalField.type
				: field.type
			: field.type;

	switch (fieldType) {
		case FieldType.Indirect:
		case FieldType.Text:
		case FieldType.SelectableText:
		case FieldType.Number:
		case FieldType.Boolean:
		case FieldType.Matrix:
		case FieldType.Email:
			return {
				...answerField,
				StoreValue: field.value,
			};
		case FieldType.Date: {
			const dateToUse =
				field.value && field.value !== undefined
					? combineDateString(field.value)
					: undefined;
			return {
				...answerField,
				StoreValue:
					dateToUse && dateToUse !== undefined
						? formatOutboundDateTime(
								moment(dateToUse, "DD/MM/YYYY"),
								"YYYY-MM-DD HH:mm:ss",
						  )
						: undefined,
			};
		}
		case FieldType.Time: {
			const timeformat = getTimeFormat();
			const timeToUse =
				field.value && field.value !== undefined
					? combineTimeString(field.value)
					: undefined;
			return {
				...answerField,
				StoreValue:
					timeToUse && timeToUse !== undefined
						? formatOutboundDateTime(moment(timeToUse, timeformat), "HH:mm")
						: undefined,
			};
		}
		case FieldType.Select: {
			const enumField = field as EnumSelectField;
			if (enumField.enumData) {
				if (field.value) {
					const enumValue = enumField.enumData.find((d) => d.value === field.value);
					return {
						...answerField,
						StoreValue: enumValue && enumValue.enumString,
					};
				}
			} else if ((field as MultiSelectField).selectType === SelectFieldType.MultiSelect) {
				return {
					...answerField,
					StoreValue: field.value
						? (field.value as number[]).filter(Boolean).join(",")
						: null,
				};
			}

			return {
				...answerField,
				StoreValue: field.value && field.value,
			};
		}
		case FieldType.ProjectSelect: {
			return {
				...answerField,
				StoreValue: field.value && field.value.id,
			};
		}
		case FieldType.GeoPosition: {
			return {
				...answerField,
				StoreValue: JSON.stringify({
					longitude: (field as GeoPositionField).long.value
						? formatInput((field as GeoPositionField).long.value)
						: null,
					latitude: (field as GeoPositionField).lat.value
						? formatInput((field as GeoPositionField).lat.value)
						: null,
					[(field as GeoPositionField).validation
						? "locationDescription"
						: "description"]: (field as GeoPositionField).description.value
						? (field as GeoPositionField).description.value
						: null,
				}),
			};
		}
		case FieldType.EntitySelectWithDefault:
		case FieldType.EntitySelect: {
			return {
				...answerField,
				StoreValue:
					field.value === "UseDefaultValue" ? null : field.value && field.value.id,
			};
		}
		case FieldType.OptionalSelect: {
			return {
				...answerField,
				StoreValue: field.value.id,
			};
		}
		default:
			throw new Error(
				`mapPortalFieldToAnswerDto() :: Unsupported field type '${field.type}'`,
			);
	}
};

export const mapSubmoduleFieldToAnswerDto = (
	subModuleAnswer: SubModuleRecordValue,
	field: Field,
): any => {
	switch (subModuleAnswer.type) {
		case FieldType.Text:
		case FieldType.SelectableText:
		case FieldType.Number:
		case FieldType.Boolean:
		case FieldType.Matrix:
		case FieldType.Email:
			return subModuleAnswer.value;
		case FieldType.Date: {
			const dateToUse =
				subModuleAnswer.value && subModuleAnswer.value !== undefined
					? combineDateString(subModuleAnswer.value)
					: undefined;
			return dateToUse && dateToUse !== undefined
				? formatOutboundDateTime(moment(dateToUse, "DD/MM/YYYY"), "YYYY-MM-DD HH:mm:ss")
				: undefined;
		}
		case FieldType.Time: {
			const timeformat = getTimeFormat();
			const timeToUse =
				subModuleAnswer.value && subModuleAnswer.value !== undefined
					? combineTimeString(subModuleAnswer.value)
					: undefined;
			return timeToUse && timeToUse !== undefined
				? formatOutboundDateTime(moment(timeToUse, timeformat), "HH:mm")
				: undefined;
		}
		case FieldType.Select: {
			const enumField = field as EnumSelectField;
			if (enumField.enumData) {
				if (subModuleAnswer.value && !Number.isNaN(parseInt(subModuleAnswer.value))) {
					const enumValue = enumField.enumData.find(
						(v) => v.value === parseInt(subModuleAnswer.value),
					);
					return enumValue && enumValue.enumText;
				}
			}
			return subModuleAnswer.value && subModuleAnswer.value;
		}
		case FieldType.Signature:
			return trimBase64String(subModuleAnswer.value);
		case FieldType.EntitySelectWithDefault: {
			const entityField = field as EntitySelectField;
			if (!entityField.value || entityField.value === "UseDefaultValue") {
				return null;
			}
			return entityField.value.id;
		}
		default:
			throw new Error(
				`mapSubmoduleFieldToAnswerDto() :: Unsupported field type '${subModuleAnswer.type}'`,
			);
	}
};

export const mapAdditionalFieldToAnswerDto = (field: Field): any => {
	switch (field.type) {
		case FieldType.Text:
		case FieldType.Number:
			return field.value;
		case FieldType.Date: {
			const dateToUse =
				field.value && field.value !== undefined
					? combineDateString(field.value)
					: undefined;
			return dateToUse && dateToUse !== undefined
				? formatOutboundDateTime(moment(dateToUse, "DD/MM/YYYY"), "YYYY-MM-DD HH:mm:ss")
				: undefined;
		}
		case FieldType.DateTime: {
			const dateTimeformat = getDateTimeFormat();
			const dateTimeToUse =
				field.value && field.value !== undefined
					? `${combineDateString(field.value)} ${combineTimeString(field.value)}`
					: undefined;
			return dateTimeToUse && dateTimeToUse !== undefined
				? formatOutboundDateTime(
						moment(dateTimeToUse, dateTimeformat),
						"YYYY-MM-DD HH:mm:ss",
				  )
				: undefined;
		}
		default:
			throw new Error(
				`mapAdditionalFieldToAnswerDto() :: Unsupported field type '${field.type}'`,
			);
	}
};

export const mapIQAnswerToAnswerDto = (
	field: Field,
	section?: Section,
	attachments?: Attachment[],
): any => {
	const answer = {
		Id: field.guid,
		DateModified: new Date(),
		QuestionnaireSectionId: section && section.guid,
		QuestionTemplateId: field.id,
		ParentId: section && section.guid,
		ParentType: 1, // section parent type
		Answer: field.value,
		IsVisible: !field.hidden,
		CurrentOrderIndex: field.originalOrderIndex,
		Comment: field.comments.value,
		IsNa: field.isNotApplicable,
		IsAnswered: field.isUpdated || !(field.defaultValue == null),
		IsAnsweredByUser: field.isUpdated,
		AttachmentFileList:
			attachments &&
			attachments.map((a) => {
				return {
					Id: uuid(),
					AnswerId: field.guid,
					Filename: a.fileName,
					FileDescription: a.description,
				};
			}),
	};

	return answer;
};

export const mapIQSingleSelectAnswerToAnswerDto = (
	field: SingleSelectField,
	section?: Section,
	attachments?: Attachment[],
): any => {
	const answer = {
		Id: field.guid,
		DateModified: new Date(),
		QuestionnaireSectionId: section && section.guid,
		QuestionTemplateId: field.id,
		ParentId: section && section.guid,
		ParentType: 1, // section parent type,
		IsVisible: !field.hidden,
		Answer: field.value && field.value,
		CurrentOrderIndex: field.originalOrderIndex,
		Comment: field.comments.value,
		IsNa: field.isNotApplicable,
		IsAnswered: field.isUpdated || (field.defaultValue != null && field.defaultValue > 0),
		IsAnsweredByUser: field.isUpdated,
		AttachmentFileList:
			attachments &&
			attachments.map((a) => {
				return {
					Id: uuid(),
					AnswerId: field.guid,
					Filename: a.fileName,
					FileDescription: a.description,
				};
			}),
	};

	return answer;
};

export const mapIQMultiSelectAnswerToAnswerDto = (
	field: MultiSelectField,
	section?: Section,
	attachments?: Attachment[],
): any => {
	const answer = {
		Id: field.guid,
		DateModified: new Date(),
		QuestionnaireSectionId: section && section.guid,
		QuestionTemplateId: field.id,
		ParentId: section && section.guid,
		ParentType: 1, // section parent type,
		IsVisible: !field.hidden,
		Answers: field.value,
		CurrentOrderIndex: field.originalOrderIndex,
		Comment: field.comments.value,
		IsNa: field.isNotApplicable,
		IsAnswered:
			field.isUpdated || (field.defaultValue != null && field.defaultValue.length > 0),
		IsAnsweredByUser: field.isUpdated,
		AttachmentFileList:
			attachments &&
			attachments.map((a) => {
				return {
					Id: uuid(),
					AnswerId: field.guid,
					Filename: a.fileName,
					FileDescription: a.description,
				};
			}),
	};

	return answer;
};

export const mapIQAuditAnswerToAnswerDto = (
	field: AuditField,
	section?: Section,
	attachments?: Attachment[],
): any => {
	const answer = {
		Id: field.guid,
		DateModified: new Date(),
		QuestionnaireSectionId: section && section.guid,
		QuestionTemplateId: field.id,
		ParentId: section && section.guid,
		ParentType: 1, // section parent type, audit does not support branching
		IsVisible: !field.hidden,
		CurrentOrderIndex: field.originalOrderIndex,
		Comment: field.comments.value,
		IsNa: field.isNotApplicable,
		IsAnswered:
			field.isUpdated || (field.defaultValue != null && field.defaultValue.length > 0),
		IsAnsweredByUser: field.isUpdated,
		InitialScore: field.scoreField.value,
		InitialComments: field.scoreComment,
		AttachmentFileList:
			attachments &&
			attachments.map((a) => {
				return {
					Id: uuid(),
					AnswerId: field.guid,
					Filename: a.fileName,
					FileDescription: a.description,
				};
			}),
	};

	return answer;
};

export const mapIQSignatureAnswerToAnswerDto = (
	field: IQSignatureField,
	section?: Section,
	attachments?: Attachment[],
): any => {
	const answer = {
		Id: field.guid,
		DateModified: new Date(),
		QuestionnaireSectionId: section && section.guid,
		QuestionTemplateId: field.id,
		ParentId: section && section.guid,
		ParentType: 1, // section parent type, audit does not support branching
		IsVisible: !field.hidden,
		CurrentOrderIndex: field.originalOrderIndex,
		Comment: field.comments.value,
		IsNa: field.isNotApplicable,
		IsAnswered:
			field.isUpdated || (field.defaultValue != null && field.defaultValue.length > 0),
		IsAnsweredByUser: field.isUpdated,
		FullName: field.fullname.value !== undefined ? field.fullname.value : "",
		Description: field.description.value !== undefined ? field.description.value : "",
		Value: field.signature.value !== undefined ? field.signature.value : "",
		AttachmentFileList:
			attachments &&
			attachments.map((a) => {
				return {
					Id: uuid(),
					AnswerId: field.guid,
					Filename: a.fileName,
					FileDescription: a.description,
				};
			}),
	};

	return answer;
};

export const mapIQSectionToAnswerDto = (section: Section, questionnaireId: any): any => {
	const sectiondto = {
		Id: section.guid,
		DateModified: new Date(),
		QuestionnaireId: questionnaireId,
		QuestionnaireSectionTemplateId: section.id,
		CurrentOrderIndex: section.orderIndex,
		IsVisible: section.isVisible,
	};

	return sectiondto;
};

export const mapIQBranchConditionToAnswerDto = (branchCondition: BranchCondition): any => {
	const bcdto = {
		Id: branchCondition.guid,
		DateModified: new Date(),
		QuestionAnswerId: branchCondition.questionAnswerId,
		BranchConditionTemplateId: branchCondition.id,
		QuestionnaireSectionId: branchCondition.questionnaireSectionId,
		IsTriggered: branchCondition.istriggered,
	};

	return bcdto;
};

export const mapSubModuleAnswersToAnswerDto = (
	subModules: SubModule[],
	subModuleRecords: SubModuleRecord[],
	subModuleFields: Field[],
	allActions: SubModuleRecord[],
	attachments: Attachment[],
): any[] => {
	const isParentPPE = (record: SubModuleRecord) => {
		const subModule = subModules.find((sm) => sm.id === record.subModuleId);
		return subModule && subModule.type === SubmoduleType.PPE;
	};

	const answers = subModuleRecords.reduce((valAcc: any[], record: SubModuleRecord) => {
		if (isParentPPE(record)) {
			if (record.values && record.values[0]) {
				const value = record.values[0];
				const PPEValues = record.values[0].value;
				PPEValues.forEach((v: PPECategoryValue) => {
					valAcc.push({
						MapSubModuleId: record.subModuleId,
						StoreSubModuleAnswers: [
							{
								MapSubModuleLineId: value.id,
								StoreValue: (v.option && v.option.id) || v.id,
							},
						],
					});
				});
			}
		} else {
			valAcc.push({
				MapSubModuleId: record.subModuleId,
				StoreSubModuleAnswers: record.values.map((val) => {
					const submoduleField = subModuleFields.find((sf) => sf.id === val.id) as Field;
					return {
						MapSubModuleLineId: val.id,
						StoreValue: mapSubmoduleFieldToAnswerDto(val, submoduleField),
					};
				}),
				StoreSubModuleActions: mapSubmoduleRecordsToActionsDto(
					allActions.filter((a) => a.parentId === record.localId),
					attachments,
				),
				StoreSubModuleAttachments: mapAttachmentsToSubModuleRecord(attachments, record),
			});
		}
		return valAcc;
	}, []);

	return answers;
};

export const mapIQChecklistAnswerToAnswerDto = (
	field: BooleanField,
	section?: Section,
	attachments?: Attachment[],
): any => {
	const answer = {
		Id: field.guid,
		DateModified: new Date(),
		QuestionnaireSectionId: section && section.guid,
		QuestionTemplateId: field.id,
		ParentId: section && section.guid,
		ParentType: 1, // section parent type
		IsVisible: !field.hidden,
		Answer: field.value === undefined || field.value === null ? null : !field.value ? 0 : 1, // EQS expects null for unanswered and not -1
		CurrentOrderIndex: field.originalOrderIndex,
		Comment: field.comments.value,
		IsNa: field.isNotApplicable,
		IsAnswered: field.isUpdated || !(field.defaultValue == null),
		IsAnsweredByUser: field.isUpdated,
		AttachmentFileList:
			attachments &&
			attachments.map((a) => {
				return {
					Id: uuid(),
					AnswerId: field.guid,
					Filename: a.fileName,
					FileDescription: a.description,
				};
			}),
	};

	return answer;
};

export const mapIQBehaviouralAnswerToAnswerDto = (
	field: BehaviouralField,
	section?: Section,
	attachments?: Attachment[],
): any => {
	const answer = {
		Id: field.guid,
		DateModified: new Date(),
		QuestionnaireSectionId: section && section.guid,
		QuestionTemplateId: field.id,
		ParentId: section && section.guid,
		ParentType: 1, // section parent type
		IsVisible: !field.hidden,
		CurrentOrderIndex: field.originalOrderIndex,
		Comment: field.comments.value,
		IsNa: field.isNotApplicable,
		IsAnswered:
			field.isUpdated || (field.defaultValue != null && field.defaultValue.length > 0),
		IsAnsweredByUser: field.isUpdated,
		SafeNo: field.safeField.value,
		CommentsSafe: field.safeComment,
		UnsafeNo: field.unSafeField.value,
		CommentsUnsafe: field.unSafeComment,
		AttachmentFileList:
			attachments &&
			attachments.map((a) => {
				return {
					Id: uuid(),
					AnswerId: field.guid,
					Filename: a.fileName,
					FileDescription: a.description,
				};
			}),
	};

	return answer;
};

export const mapIQDateTimeAnswerToAnswerDto = (
	field: IQDateTimeField,
	section?: Section,
	attachments?: Attachment[],
): any => {
	const dateTimeformat = getDateTimeFormat();
	const dateTimeToUse =
		field.value && field.value !== undefined
			? `${combineDateString(field.value)} ${combineTimeString(field.value)}`
			: undefined;
	const value =
		dateTimeToUse && dateTimeToUse !== undefined
			? formatOutboundDateTime(moment(dateTimeToUse, dateTimeformat), "YYYY-MM-DD HH:mm:ss")
			: undefined;

	const answer = {
		Id: field.guid,
		DateModified: new Date(),
		QuestionnaireSectionId: section && section.guid,
		QuestionTemplateId: field.id,
		ParentId: section && section.guid,
		ParentType: 1, // section parent type
		IsVisible: !field.hidden,
		Answer: value,
		CurrentOrderIndex: field.originalOrderIndex,
		Comment: field.comments.value,
		IsNa: field.isNotApplicable,
		IsAnswered: (field.isUpdated && value !== undefined) || !(field.defaultValue == null),
		IsAnsweredByUser: field.isUpdated && value !== undefined,
		AttachmentFileList:
			attachments &&
			attachments.map((a) => {
				return {
					Id: uuid(),
					AnswerId: field.guid,
					Filename: a.fileName,
					FileDescription: a.description,
				};
			}),
	};

	return answer;
};

export const mapIQDateAnswerToAnswerDto = (
	field: IQDateField,
	section?: Section,
	attachments?: Attachment[],
): any => {
	const dateToUse =
		field.value && field.value !== undefined ? combineDateString(field.value) : undefined;
	const value =
		dateToUse && dateToUse !== undefined
			? formatOutboundDateTime(moment(dateToUse, "DD/MM/YYYY"), "YYYY-MM-DD HH:mm:ss")
			: undefined;

	const answer = {
		Id: field.guid,
		DateModified: new Date(),
		QuestionnaireSectionId: section && section.guid,
		QuestionTemplateId: field.id,
		ParentId: section && section.guid,
		ParentType: 1, // section parent type
		IsVisible: !field.hidden,
		Answer: value,
		CurrentOrderIndex: field.originalOrderIndex,
		Comment: field.comments.value,
		IsNa: field.isNotApplicable,
		IsAnswered: (field.isUpdated && value !== undefined) || !(field.defaultValue == null),
		IsAnsweredByUser: field.isUpdated && value !== undefined,
		AttachmentFileList:
			attachments &&
			attachments.map((a) => {
				return {
					Id: uuid(),
					AnswerId: field.guid,
					Filename: a.fileName,
					FileDescription: a.description,
				};
			}),
	};

	return answer;
};

export const mapIQTimeAnswerToAnswerDto = (
	field: TimeField,
	section?: Section,
	attachments?: Attachment[],
): any => {
	const timeformat = getTimeFormat();
	const timeToUse =
		field.value && field.value !== undefined ? combineTimeString(field.value) : undefined;
	const value =
		timeToUse && timeToUse !== undefined
			? formatOutboundDateTime(moment(timeToUse, timeformat), "HH:mm")
			: undefined;

	const answer = {
		Id: field.guid,
		DateModified: new Date(),
		QuestionnaireSectionId: section && section.guid,
		QuestionTemplateId: field.id,
		ParentId: section && section.guid,
		ParentType: 1, // section parent type
		IsVisible: !field.hidden,
		Answer: value,
		CurrentOrderIndex: field.originalOrderIndex,
		Comment: field.comments.value,
		IsNa: field.isNotApplicable,
		IsAnswered: field.isUpdated || !(field.defaultValue == null),
		IsAnsweredByUser: field.isUpdated,
		AttachmentFileList:
			attachments &&
			attachments.map((a) => {
				return {
					Id: uuid(),
					AnswerId: field.guid,
					Filename: a.fileName,
					FileDescription: a.description,
				};
			}),
	};

	return answer;
};

/**
 * Returns array of valid actions extracted from submodule records.
 */

export const mapSubmoduleRecordsToActionsDto = (
	submoduleRecords: SubModuleRecord[],
	attachments?: Attachment[],
): any => {
	const actions = submoduleRecords.reduce((validActions: any[], record: SubModuleRecord) => {
		const category = record.values.find((smr) => smr.id === LocalFieldIds.ActionCategory);
		const subCategory = record.values.find((smr) => smr.id === LocalFieldIds.ActionSubCategory);
		const priority = record.values.find((smr) => smr.id === LocalFieldIds.ActionPriority);
		const dueDate = record.values.find((smr) => smr.id === LocalFieldIds.ActionDueDate);
		const header = record.values.find((smr) => smr.id === LocalFieldIds.ActionTitle);
		const detail = record.values.find((smr) => smr.id === LocalFieldIds.ActionDetail);
		const forUser = record.values.find((smr) => smr.id === LocalFieldIds.ActionForUser);
		let recordAttachments: { FileName: string; FileDescription: string | undefined }[] = [];

		if (attachments?.length) {
			recordAttachments = mapAttachmentsToSubModuleRecord(attachments, record);
		}

		if (
			header?.value !== undefined &&
			dueDate &&
			dueDate.value !== undefined &&
			priority &&
			priority.value !== undefined
		) {
			const dueDateToUse = combineDateString(dueDate.value);
			const action: any = {
				DueDate: formatOutboundDateTime(
					moment(dueDateToUse, "DD/MM/YYYY"),
					"YYYY-MM-DD HH:mm:ss",
				),
				Header: header.value,
				PriorityDictionaryId: priority.value,
			};

			if (record.parentGuid) {
				action.QuestionId = record.parentGuid;
			}
			if (category?.value !== undefined) {
				action.CategoryDictionaryId = category.value;
			}
			if (subCategory?.value !== undefined) {
				action.SubCategoryDictionaryId = subCategory.value;
			}
			if (detail?.value !== undefined) {
				action.Detail = detail.value;
			}
			if (forUser?.value !== undefined && forUser.value !== "UseDefaultValue") {
				action.ForUserId = forUser.value.id;
			}
			if (recordAttachments.length) {
				action.Attachments = recordAttachments;
			}

			validActions.push(action);
		}
		return validActions;
	}, []);

	return actions;
};

const mapAttachmentsToSubModuleRecord = (
	attachments: Attachment[],
	submoduleRecord: SubModuleRecord,
) => {
	return attachments
		.filter(
			(a) =>
				a.parentType === AttachmentParentType.Submodule &&
				a.parentGuid === submoduleRecord.localId,
		)
		.map((a) => ({ FileName: a.fileName, FileDescription: a.description }));
};

export const mapSubModuleRecordsToAssignmentDto = (fields: Field[]) => {
	if (fields && fields.length) {
		const assignmentDTO: Partial<IAssignmentDTO> = {};

		const assignedIdField = fields.find(
			(field) => field.id === LocalFieldIds.SubmissionAssigned,
		);
		const assignedDueDateField = fields.find(
			(field) => field.id === LocalFieldIds.SubmissionDueDate,
		);
		const assignedCommentsField = fields.find(
			(field) => field.id === LocalFieldIds.SubmissionComments,
		);

		if (assignedIdField && assignedIdField.value) {
			assignmentDTO.Assigned = assignedIdField.value.id;
		}

		if (assignedDueDateField && assignedDueDateField.value) {
			const dueDateToUse = combineDateString(assignedDueDateField.value);

			const dateToSend = moment(dueDateToUse, "DD/MM/YYYY");

			if (dateToSend.isValid()) {
				assignmentDTO.DueDate = formatOutboundDateTime(dateToSend, "YYYY-MM-DD HH:mm:ss");
			}
		}

		if (assignedCommentsField && assignedCommentsField.value) {
			assignmentDTO.ApprovalComment = assignedCommentsField.value;
		}

		return assignmentDTO;
	}

	return null;
};
