import axios from "axios";
import { getUserManager } from "../../helpers/AuthenticationHelpers";
import { CommonActionTypes } from "../../state/components/common/actions/types";
import { ErrorWithData } from "../../models/errors/ErrorWithData";
import { forceLoginModal, store } from "../../state";
import type { AxiosRequestConfig, AxiosResponse } from "axios";

export interface IHttpResponse<T> extends AxiosResponse {
	parsedBody?: T;
}

export const http = <T>(request: AxiosRequestConfig): Promise<IHttpResponse<T>> => {
	return new Promise((resolve, reject) => {
		let response: IHttpResponse<T>;
		axios(request)
			.then((res) => {
				response = res;
				response.parsedBody = res.data;
				resolve(response);
			})
			.catch((e) => {
				if (axios.isCancel(e)) {
					// The request has been cancelled. Ignore.
					// Log-Error "Request canceled", error - not sending to RG to not flood it
				} else {
					if (e.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						if (e.response.status === 403) {
							store.dispatch({
								type: CommonActionTypes.ErrorHappened,
								key: "error:ipRestricted",
								hasError: true,
							});
							reject(e);
							return;
						}
						if (e.response.data) {
							// If error details are provided, reject with error details
							if (
								e.response.data.Details &&
								Array.isArray(e.response.data.Details) &&
								e.response.data.Details.length > 0
							) {
								reject(
									new ErrorWithData(
										`Network error, ${e.response.data.Details.toString()}`,
										e.response.data.Details,
										e.response.status,
									),
								);
							}
							if (
								e.response.data.Errors &&
								Array.isArray(e.response.data.Errors) &&
								e.response.data.Errors.length > 0
							) {
								reject(
									new ErrorWithData(
										`Network error, ${e.response.data.Errors.toString()}`,
										e.response.data.Errors,
										e.response.status,
									),
								);
							}
						}
					}
					// Log-Error error.config - not sending to RG to not flood it
					if (e instanceof Error) {
						reject(e);
					} else {
						reject(new Error("Network error"));
					}
				}
			});
	});
};

export const get = async <T>(
	path: string,
	args: AxiosRequestConfig = {},
): Promise<IHttpResponse<T>> => {
	// TODO: Check this, as it's mutation the original prop coming in. TUT TUT
	args = {
		...args,
		method: "get",
		url: path,
	};
	const response = await http<T>(args);
	return response;
};

export const post = async <T>(
	path: string,
	args: AxiosRequestConfig = {},
	body: any,
): Promise<IHttpResponse<T>> => {
	// TODO: Check this, as it's mutation the original prop coming in. TUT TUT
	args = {
		...args,
		method: "post",
		url: path,
		data: JSON.stringify(body),
	};
	const response = await http<T>(args);
	return response;
};

export const postMultiPart = async <T>(
	path: string,
	args: AxiosRequestConfig = {},
	body: any,
): Promise<IHttpResponse<T>> => {
	// TODO: Check this, as it's mutation the original prop coming in. TUT TUT
	args = {
		...args,
		method: "post",
		url: path,
		data: body,
	};
	const response = await http<T>(args);
	return response;
};

export const put = async <T>(
	path: string,
	args: AxiosRequestConfig = {},
	body: any,
): Promise<IHttpResponse<T>> => {
	// TODO: Check this, as it's mutation the original prop coming in. TUT TUT
	args = {
		...args,
		method: "put",
		url: path,
		data: JSON.stringify(body),
	};
	const response = await http<T>(args);
	return response;
};

type httpRequest = <T>(
	path: string,
	args: AxiosRequestConfig | undefined,
	body: any,
) => Promise<IHttpResponse<T>>;

export const withSignIn = async function <T>(httpRequest: httpRequest) {
	return async (path: string, args: AxiosRequestConfig = {}, body?: any) => {
		return httpRequest<T>(path, args, body).catch(async (error) => {
			if (error instanceof ErrorWithData && error.status === 401) {
				const userManger = await getUserManager();
				return userManger
					.signinSilent()
					.then(async (user) => {
						if (user && args) {
							args.headers = {
								...args.headers,
								Authorization: `Bearer ${user.access_token}`,
							};
							return httpRequest<T>(path, args, body);
						}
						forceLoginModal();
						throw error;
					})
					.catch(() => {
						forceLoginModal();
						throw error;
					});
			}
			throw error;
		});
	};
};

export const withSignInPut = async <T>(
	path: string,
	args: AxiosRequestConfig = {},
	body: any,
): Promise<IHttpResponse<T>> => {
	return withSignIn<T>(put).then((httpRequest) => httpRequest(path, args, body));
};

export const withSignInGet = async <T>(
	path: string,
	args: AxiosRequestConfig = {},
): Promise<IHttpResponse<T>> => {
	return withSignIn<T>(get).then((httpRequest) => httpRequest(path, args));
};

export const withSignInPost = async <T>(
	path: string,
	args: AxiosRequestConfig = {},
	body: any,
): Promise<IHttpResponse<T>> => {
	return withSignIn<T>(post).then((httpRequest) => httpRequest(path, args, body));
};

export const withSignInPostMultiPart = async <T>(
	path: string,
	args: AxiosRequestConfig = {},
	body: any,
): Promise<IHttpResponse<T>> => {
	return withSignIn<T>(postMultiPart).then((httpRequest) => httpRequest(path, args, body));
};
