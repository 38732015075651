import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { decodeBase64 } from "tweetnacl-util";
import { forceLoginModal, store } from "../state";
import { PortalAuthenticationMode } from "../models/portal";
import { QuestionnaireEncryptionVersion } from "../models/questionnaire/Enums";
import { ConfigPropertyKey, getConfigurationOption } from "./ConfigHelper";
import type { User } from "oidc-client-ts";

const PRE_SING_IN_URL_KEY = "preSignInURL";

let testUser: User | null = null;

export async function getAuthority() {
	const authority = await getConfigurationOption(ConfigPropertyKey.Authority);
	return authority;
}

export async function getPKCESecret() {
	const pkceSecret = await getConfigurationOption(ConfigPropertyKey.PkceSecret);
	return pkceSecret;
}

export function getUserManager() {
	return UserManagerSingleton.getInstance();
}

export const getToken = async () => {
	const token = store.getState().auth.token;
	if (
		token &&
		store.getState().portal.portals[0] &&
		store.getState().portal.portals[0].authenticationMode === PortalAuthenticationMode.Public
	) {
		return token;
	}
	let user = null;
	try {
		user = await getAuthUser();
	} catch {
		console.error("Failed to get token");
	}
	if (user && user.access_token) {
		return user.access_token;
	}
	return token;
};

export const getCustomerKey = () => {
	return window.location.pathname.split("/")[1] || "";
};

export const getCustomerKeyLowercase = () => {
	return getCustomerKey().toLowerCase();
};

export const getAuthUser = async () => {
	const userManager = await getUserManager();
	const user = await userManager.getUser();
	if (testUser) {
		if (!user || user.profile.sub !== testUser.profile.sub) {
			forceLoginModal();
			throw new Error("wrongUserError");
		}
	} else {
		testUser = user;
	}
	return user;
};

export const getEncryptionKey = function (
	user: User,
	encryptionVersion: QuestionnaireEncryptionVersion = QuestionnaireEncryptionVersion.V1,
) {
	if (!user || !user.profile) {
		return "";
	}
	const key = user.profile[encryptionVersion] as string;
	const array = decodeBase64(key);
	return decodeBase64(key).slice(array.length - 32);
};

export const getPortalKey = () => {
	return window.location.pathname.split("/")[3] || "";
};

export const getPortalKeyLowercase = () => {
	return getPortalKey().toLowerCase();
};

export const storePreSignInURL = () => {
	sessionStorage.setItem(
		PRE_SING_IN_URL_KEY,
		window.location.pathname + window.location.search + window.location.hash,
	);
};

export const getPreSignInURL = () => {
	const preSignInURL = sessionStorage.getItem(PRE_SING_IN_URL_KEY);
	if (preSignInURL && isPreSignInURLAllowed(preSignInURL)) {
		return preSignInURL;
	}
	return null;
};

export const clearPreSignInURL = () => {
	sessionStorage.removeItem(PRE_SING_IN_URL_KEY);
};

export const isPreSignInURLAllowed = (preSignInURL: string) => {
	const currentURLParts = window.location.pathname.toLowerCase().split("/");
	const preSignInURLParts = preSignInURL.toLowerCase().split("/");
	//check if customer and portal are the same
	return (
		currentURLParts[1] === preSignInURLParts[1] && currentURLParts[3] === preSignInURLParts[3]
	);
};

class UserManagerSingleton {
	private static instance: UserManagerWithTenantCheck;

	static async getInstance() {
		if (!UserManagerSingleton.instance) {
			const customerKey = getCustomerKey();
			const portalKey = getPortalKey();
			const authority = await getAuthority();
			const deviceId = window.localStorage.getItem("raygun4js-userid");
			const pkceSecret = await getPKCESecret();
			if (!UserManagerSingleton.instance) {
				const userManager = new UserManagerWithTenantCheck({
					authority,
					client_id: "assuregoplus.pkce",
					response_type: "code",
					scope: "openid email profile tenants",
					acr_values: `tenant=${customerKey};deviceId=${deviceId}`,
					redirect_uri: `${window.location.origin}/${customerKey}/p/${portalKey}/authentication/callback`,
					silent_redirect_uri: `${window.location.origin}/${customerKey}/p/${portalKey}/authentication/silent_callback`,
					post_logout_redirect_uri: `${window.location.origin}/${customerKey}/p/${portalKey}`,
					client_secret: pkceSecret,
					userStore: new WebStorageStateStore({ store: window.localStorage }),
				});
				userManager.clearStaleState();
				UserManagerSingleton.instance = userManager;
			}
		}
		return UserManagerSingleton.instance;
	}
}

export class UserManagerWithTenantCheck extends UserManager implements UserManager {
	getUser(): Promise<User | null> {
		return super.getUser().then((user) => {
			if (
				user &&
				user.profile &&
				user.profile.tenantid &&
				(user.profile.tenantid as string).toLowerCase() !== getCustomerKey().toLowerCase()
			) {
				return null;
			}
			return user;
		});
	}

	isWrongTenant(): Promise<boolean> {
		return super.getUser().then((user) => {
			const wrongTenant = !!(
				user &&
				user.profile.tenantid &&
				(user.profile.tenantid as string).toLowerCase() !== getCustomerKey().toLowerCase()
			);
			return wrongTenant;
		});
	}
}
