import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIHealthContext } from "../../../../services/health/implementations/healthContext/healthContext";
import { OnlineStatus } from "../../../../services/health/implementations/healthMonitor";
import { getMatrices } from "../../../../state/components/questionnaire";
import type { Field } from "../../../../models/fields/Field";
import type { MatrixField } from "../../../../models/fields/MatrixField";
import type { Matrix } from "../../../../models/matrix/matrix";
import type { State } from "../../../../state";

export const useMatrixFix = (field: MatrixField) => {
	const dispatch = useDispatch();
	const health = useContext(APIHealthContext);
	const matrix: Matrix | undefined = useSelector<State, Matrix | undefined>((state) =>
		state.questionnaire.matrixes.find((m) => m.id === field.matrixId),
	);
	const templateId = useSelector<State, number | undefined>(
		(state) =>
			state.questionnaire.questionnaire && state.questionnaire.questionnaire.templateId,
	);
	const fields = useSelector<State, Field[]>((state) => state.questionnaire.fields);

	useEffect(() => {
		if (!matrix && templateId && health === OnlineStatus.Available) {
			dispatch(getMatrices(fields, templateId));
		}
		// run only on first render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
