import moment from "moment";
import { Typeson, builtin } from "typeson-registry";
import JSZip from "jszip";
import { isEncryptedQuestionnaireTemplate } from "../../../../models/questionnaire";
import { decrypt, decryptArrayBuffer } from "../../../../helpers/EncryptionHelper";
import { getAuthUser, getEncryptionKey } from "../../../../helpers/AuthenticationHelpers";
import { localData } from "../../../../services/localData";
import type { User } from "oidc-client-ts";
import type {
	QuestionnaireTemplate,
	StoredQuestionnaireTemplate,
} from "../../../../models/questionnaire";
import type { Attachment, StoredAttachment } from "../../../../models/attachments/Attachment";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stringifyWithTypes = (data: any) => {
	const typeson = new Typeson().register(builtin);
	return typeson.stringify(data, null, 2);
};

export const parseWithTypes = (data: string) => {
	const typeson = new Typeson().register(builtin);
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return typeson.parse(data);
};

export const generateFileName = (name: string, extension: string) => {
	const applicationId = window.localStorage.getItem("raygun4js-userid");
	return `${name} ${moment().format("YYYYMMDD HHMMSS")} ${
		applicationId ? applicationId : "no application id"
	}`
		.replace(/[^a-z0-9]/gi, "_")
		.concat(`.${extension}`)
		.toLowerCase();
};

export const questionnaireTemplateFromStoredQuestionnnaire = (
	questionnaire: StoredQuestionnaireTemplate,
	user: User | null,
) => {
	if (isEncryptedQuestionnaireTemplate(questionnaire) && user) {
		const decryptedQuestionnnare = decrypt(
			questionnaire.encryptedQuestionnaire,
			getEncryptionKey(user, questionnaire.encryptionVersion) as Uint8Array,
		);
		return decryptedQuestionnnare as QuestionnaireTemplate;
	}
	return questionnaire as QuestionnaireTemplate;
};

export const generateFormZipFile = async (guid: string) => {
	const zip = new JSZip();
	const authUser = await getAuthUser();
	const form = await localData.questionnaires.where("questionnaire.id").equals(guid).first();

	if (form) {
		const decodedForm = questionnaireTemplateFromStoredQuestionnnaire(form, authUser);
		const attachments = (await localData.attachments
			.where("recordId")
			.equals(guid)
			.toArray()) as StoredAttachment[];
		attachments.forEach((attachment) => {
			if (attachment.userId && authUser && attachment.file instanceof ArrayBuffer) {
				const file = decryptArrayBuffer(
					attachment.file,
					getEncryptionKey(authUser, attachment.encryptionVersion) as Uint8Array,
				);
				delete attachment.file;
				zip.file(attachment.id, file);
			} else {
				zip.file(attachment.id, new Blob([attachment.file]));
			}
		});
		decodedForm.userId = "";
		attachments.forEach((attachment) => {
			attachment.userId = "";
			delete attachment.file;
		});
		const data = await stringifyWithTypes({
			form: decodedForm,
			attachments,
		});
		zip.file("form.json", data);
		const generated = await zip.generateAsync({ type: "blob" });
		return generated;
	}
};

export const loadFormFromZipFile = async (file: File) => {
	const loadedZip = await JSZip.loadAsync(file);

	const form = await loadedZip.files["form.json"].async("string");
	const data = parseWithTypes(form);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	const attachmentPromises = data.attachments.map(async (attachment: Attachment) => {
		const file = await loadedZip.files[attachment.id].async("arraybuffer");
		return {
			...attachment,
			file,
		};
	});
	const attachmentsArray = await Promise.all(attachmentPromises);

	await localData.transaction("rw", localData.questionnaires, localData.attachments, async () => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		await localData.questionnaires.put(data.form);
		await localData.attachments.bulkPut(attachmentsArray);
	});
};
