import { createContext, useCallback, useState } from "react";
import { v4 as uuid } from "uuid";

export interface Toast {
	uuid?: string;
	content: React.ReactNode;
	timeout?: number;
}

export const ToastContext = createContext({
	toasts: [] as Toast[],
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	putToast: (toast: Toast) => "" as string,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	removeToast: (id: string) => {},
});

type Props = {
	children?: React.ReactNode;
};

export const ToastProvider = ({ children }: Props) => {
	const [toasts, setToasts] = useState<Toast[]>([]);

	const removeToast = useCallback((id: string) => {
		setToasts((prevToasts) => {
			return prevToasts.filter((t) => t.uuid !== id);
		});
	}, []);

	const putToast = useCallback(
		(toast: Toast) => {
			if (!toast.uuid) {
				toast.uuid = uuid();
				setToasts((prevToasts) => prevToasts.concat(toast));
			} else if (!toasts.some((t) => t.uuid === toast.uuid)) {
				setToasts((prevToasts) => prevToasts.concat(toast));
			} else {
				setToasts((prevToasts) =>
					prevToasts.map((t) => (t.uuid === toast.uuid ? toast : t)),
				);
			}
			if (toast.timeout) {
				window.setTimeout(() => {
					removeToast(toast.uuid ?? "");
				}, toast.timeout);
			}
			return toast.uuid;
		},
		[removeToast, toasts],
	);

	return (
		<ToastContext.Provider
			value={{
				toasts,
				putToast,
				removeToast,
			}}
		>
			{children}
		</ToastContext.Provider>
	);
};
