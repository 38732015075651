import { DefaultPortalService } from "../../../../services/portal";
import { CommonActionTypes } from "../../common/actions/types";
import { DefaultAttachmentService } from "../../../../services/attachment";
import { QuestionnaireStatus } from "../../../../models/portal";
import { DefaultQuestionnaireService } from "../../../../services/questionnaire";
import { DefaultLocalDataService } from "../../../../services/localData";
import { loadOrgUnits } from "../../orgUnit";
import {
	HealthMonitorSingleton,
	OnlineStatus,
} from "../../../../services/health/implementations/healthMonitor";
import { rgLog } from "../../../../services/log";
import { isBrowserIE } from "../../../../helpers/ieHelper";
import { getCustomerKey } from "../../../../helpers/AuthenticationHelpers";
import { changeLanguage } from "../../../../translations/i18n";
import { getDefaultPortalLanguage } from "../../../../translations/helpers/i18nHelpers";
import { PortalActionTypes } from "./types";
import type { QuestionnaireService } from "../../../../services/questionnaire";
import type { QuestionnaireStub } from "../../../../models/portal";
import type { AttachmentService } from "../../../../services/attachment";
import type { State } from "../../../model";
import type { PortalService } from "../../../../services/portal";
import type { PortalAction } from "./definitions";
import type { CommonAction } from "../../common/actions/definitions";
import type { SimpleThunkAction } from "../../../utils/thunk";

export const loadPortal = (
	portalKey: string,
): SimpleThunkAction<PortalAction | CommonAction, State> => {
	return async (dispatch) => {
		try {
			const healthApi = await HealthMonitorSingleton.getInstance().checkIfOnline();
			const localDataService = new DefaultLocalDataService();
			const customerKey = getCustomerKey();

			if (healthApi === OnlineStatus.Available) {
				const portalService: PortalService = new DefaultPortalService({
					subdomain: "Portal",
				});
				const portal = await portalService.getOneByKeyAndCustomerKey(
					portalKey,
					customerKey,
				);

				const attachmentService: AttachmentService = new DefaultAttachmentService({
					subdomain: "Attachment",
				});

				if (!portal) {
					throw new Error("Failed to retrieve portal");
				}

				// TODO: We should be making sure the portal.logoGuid is no undefined, not just coercing it to a string
				await attachmentService
					.getOneByGuidAndCustomerKey(portal.logoGuid as string, customerKey)
					.then((blob) => {
						if (blob.type === "image/png") {
							portal.logo = blob;
						}
					})
					.catch(() => {
						// ignore - no logo
					});

				changeLanguage(
					(await localDataService.getLanguageByPortalKeyAndCustomerKey(
						portal.key,
						customerKey,
					)) || getDefaultPortalLanguage(portal),
				);

				dispatch({
					type: PortalActionTypes.Loaded,
					portal,
				});

				for await (const q of portal.questionnaireStubs.filter((q) => q.imageGuid)) {
					// TODO: We should be making sure the portal.logoGuid is no undefined, not just coercing it to a string
					await attachmentService
						.getOneByGuidAndCustomerKey(q.imageGuid as string, customerKey)
						.then((blob) => {
							q.image = blob;
						})
						.catch(() => {
							// ignore - use default questionnare thumb
						});
				}

				dispatch({
					type: PortalActionTypes.Loaded,
					portal: { ...portal },
				});

				if (!isBrowserIE()) {
					localDataService.savePortal(portal);
				}
			} else {
				localDataService.getPortalByKey(portalKey, customerKey).then(async (portal) => {
					if (portal) {
						changeLanguage(
							(await localDataService.getLanguageByPortalKeyAndCustomerKey(
								portal.key,
								customerKey,
							)) || getDefaultPortalLanguage(portal),
						);

						dispatch({
							type: PortalActionTypes.Loaded,
							portal,
						});
					} else {
						dispatch({
							type: CommonActionTypes.ErrorHappened,
							key: "error:noPortalsAvailableOffline",
							hasError: true,
						});
					}
				});
			}
		} catch (ex) {
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:dataRetrievalFailed",
				hasError: true,
			});
		}
	};
};

export const sortQuestionnaireStubs = (sortingKey: string): SimpleThunkAction<PortalAction> => {
	return (dispatch) => {
		dispatch({
			type: PortalActionTypes.QuestionnaireStubsSorted,
			sortingKey,
		});
	};
};

export const saveInitialSetup = (
	stubs: QuestionnaireStub[],
	portalKey: string,
): SimpleThunkAction<PortalAction | CommonAction, State> => {
	return (dispatch, getState) => {
		const localDataService = new DefaultLocalDataService();

		const portal = getState().portal.portals.find((p) => p.key === portalKey);

		if (portal) {
			portal.questionnaireStubs = stubs;
			portal.initialSetupCompleted = true;
			localDataService.savePortal(portal);
		}

		dispatch({
			type: PortalActionTypes.SaveInitialSetup,
			stubs,
			portalKey,
		});
	};
};

export const updateInitialSetupFlag = (
	portalKey: string,
	initialSetupCompleted: boolean,
): SimpleThunkAction<PortalAction, State> => {
	return (dispatch) =>
		dispatch({
			type: PortalActionTypes.UpdateInitialSetupFlag,
			portalKey,
			initialSetupCompleted,
		});
};

export const removeQuestionnaireFromDevice = (
	questionnaireKey: string,
	portalKey: string,
): SimpleThunkAction<PortalAction | CommonAction, State> => {
	return async (dispatch, getState) => {
		const localDataService = new DefaultLocalDataService();

		const portal = getState().portal.portals.find((p) => p.key === portalKey);

		if (portal) {
			for (const s of portal.questionnaireStubs) {
				if (s.key === questionnaireKey) {
					s.makeAvailableOffline = false;
					await localDataService.deleteTemplate(s.key);
				}
			}
			await localDataService.savePortal(portal);
		}

		dispatch({
			type: PortalActionTypes.RemoveQuestionnaire,
			questionnaireKey,
			portalKey,
		});
	};
};

export const markQuestionnaireAsDownloadToDevice = (
	questionnaireKey: string,
	portalKey: string,
): SimpleThunkAction<PortalAction | CommonAction, State> => {
	return async (dispatch, getState) => {
		const localDataService = new DefaultLocalDataService();

		const portal = getState().portal.portals.find((p) => p.key === portalKey);

		if (portal) {
			portal.questionnaireStubs.forEach((s) => {
				if (s.key === questionnaireKey) {
					s.makeAvailableOffline = true;
				}
			});
			await localDataService.savePortal(portal);
		}

		dispatch({
			type: PortalActionTypes.DownloadQuestionnaireToDevice,
			questionnaireKey,
			portalKey,
		});
	};
};

export const downloadSelectedQuestionnaires = (
	portalKey: string,
): SimpleThunkAction<PortalAction | CommonAction, State> => {
	return (dispatch, getState) => {
		const questionnaireService: QuestionnaireService = new DefaultQuestionnaireService({
			subdomain: "Questionnaire",
		});

		const portal = getState().portal.portals.find((p) => p.key === portalKey);

		if (portal) {
			dispatch(loadOrgUnits(portal.maskedOrgUnitId || 0, portal.customerKey));

			const questionnairesToDownload = portal.questionnaireStubs.filter(
				(s) => s.makeAvailableOffline,
			);

			questionnairesToDownload.forEach((stub) => {
				dispatch({
					type: PortalActionTypes.UpdateQuestionnaireTemplateStatus,
					portalKey,
					questionnaireKey: stub.key,
					status: QuestionnaireStatus.Downloading,
					errorMsg: "",
				});

				questionnaireService
					.saveTemplateForOffline(stub.key as unknown as number, portal)
					.then(() => {
						dispatch({
							type: PortalActionTypes.UpdateQuestionnaireTemplateStatus,
							portalKey,
							questionnaireKey: stub.key,
							status: QuestionnaireStatus.Downloaded,
							errorMsg: "",
						});
					})
					.catch((e: Error) => {
						rgLog("send", e);
						dispatch({
							type: PortalActionTypes.UpdateQuestionnaireTemplateStatus,
							portalKey,
							questionnaireKey: stub.key,
							status: QuestionnaireStatus.Error,
							errorMsg: e.message || e.toString(),
						});
					});
			});
		}
	};
};

export const updateTemplateSyncFlag = (
	portalKey: string,
	syncFlag: boolean,
): SimpleThunkAction<PortalAction | CommonAction, State> => {
	return async (dispatch, getState) => {
		const localDataService = new DefaultLocalDataService();
		const state = getState();
		const portal = state.portal.portals[0];
		portal.questionnaireTemplateSyncRequired = syncFlag;
		await localDataService.savePortal(portal);

		dispatch({
			type: PortalActionTypes.UpdateTemplateSyncFlag,
			portalKey,
			syncFlag,
		});
	};
};
