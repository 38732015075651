import { Component } from "react";
import { connect } from "react-redux";
import { rgLog } from "../../../services/log";
import { ErrorBoundaryPresentation } from "./ErrorBoundary.presentation";

type Props = {
	common: {
		hasError: boolean;
		redirectUrl: string;
		key: any;
		parameters: any;
	};
	children: React.ReactNode;
};

type State = {
	hasError: boolean;
	error: any;
	errorInfo: any;
};

class ErrorBoundaryComponent extends Component<Props, State> {
	state = {
		hasError: false,
		error: null,
		errorInfo: null,
	};

	componentDidMount = () => {
		if (this.props.common.hasError) {
			this.setState({ hasError: true });
		}
	};

	componentDidCatch(e: any, _errorInfo: any) {
		// You can also log the error to an error reporting service
		rgLog("send", e);
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error };
	}

	render() {
		const { hasError, error } = this.state;
		const { common, children } = this.props;

		if (hasError || (common && common.hasError && common.key)) {
			// You can render any custom fallback UI
			return (
				<ErrorBoundaryPresentation
					error={error as unknown as Error}
					message={[common.key, common.parameters]}
				/>
			);
		}

		return children;
	}
}

const mapStateToProps = (state: any) => {
	return { common: state.common };
};

const ErrorBoundary = connect(mapStateToProps)(ErrorBoundaryComponent);

export { ErrorBoundary };
