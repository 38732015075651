import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { AnimationWrapper } from "../../../../../components/animation/AnimationWrapper";
import { AnimationSlideFade } from "../../../../../components/animation/AnimationSlideFade";
import type { Section } from "../../../../../../models/questionnaire";
import "./SectionNav.styles.scss";

interface Props {
	sections: Section[] | undefined;
	sectionIndex: number;
	sectionsWithValidationErr: number[];
	changeSection: (index: number) => void;
}

export const SectionNavPresentation = ({
	changeSection,
	sectionIndex,
	sections,
	sectionsWithValidationErr,
}: Props) => {
	const { t } = useTranslation();

	return (
		<div aria-label={t("display:ariaLabelFormNavigation")} role="region">
			<div className="she-section-nav">
				<div className="she-section-nav-list">
					<ul>
						<AnimationWrapper>
							{sections?.map((section) => {
								const hasValidation: boolean = sectionsWithValidationErr.includes(
									section.id,
								);
								return (
									<AnimationSlideFade as="li" key={section.id}>
										<button
											aria-current={
												section.orderIndex === sectionIndex
													? "step"
													: undefined
											}
											className={classNames(
												section.orderIndex === sectionIndex
													? "she-section-nav-list-item she-section-nav-list-item-current"
													: "she-section-nav-list-item",
												hasValidation &&
													"she-section-nav-list-item-validation",
											)}
											onClick={() => {
												changeSection(section.orderIndex);
											}}
											type="button"
										>
											{section.name}
										</button>
									</AnimationSlideFade>
								);
							})}
						</AnimationWrapper>
					</ul>
				</div>
			</div>
		</div>
	);
};
