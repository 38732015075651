import { ConfigPropertyKey, getConfigurationOption } from "../helpers/ConfigHelper";
import { getCustomerKey, getPortalKey, getToken } from "../helpers/AuthenticationHelpers";
import type { AxiosRequestConfig } from "axios";

export interface ServiceConfiguration {
	subdomain: string;
	endpointUrl?: string;
}

export class BaseService {
	config: ServiceConfiguration;
	trackerId: string;
	portalKey: string;
	customerKey: string;

	constructor(config: ServiceConfiguration) {
		this.config = config;
		this.trackerId = window.localStorage.getItem("raygun4js-userid") || "no-tracker-id";
		this.portalKey = getPortalKey();
		this.customerKey = getCustomerKey();
	}

	public async getAuthHeaders(): Promise<AxiosRequestConfig> {
		const token = await getToken();
		const header = {
			headers: {
				Authorization: `Bearer ${token}`,
				"portal-key": this.portalKey,
				"customer-key": this.customerKey,
				"Accept-Language": "en-GB",
				"Cache-Control": "no-cache",
				Pragma: "no-cache",
				Expires: "0",
				"Tracker-Id": this.trackerId,
			},
		};

		return header;
	}

	public async getAuthHeadersInit(): Promise<HeadersInit> {
		const token = await getToken();
		return {
			Authorization: `Bearer ${token}`,
			"portal-key": this.portalKey,
			"customer-key": this.customerKey,
			"Content-Type": "application/json",
			"Accept-Language": "en-GB",
			"Tracker-Id": this.trackerId,
		};
	}

	public async getAuthHeadersWithoutContentType(): Promise<HeadersInit> {
		const token = await getToken();

		return {
			Authorization: `Bearer ${token}`,
			"portal-key": this.portalKey,
			"customer-key": this.customerKey,
			"Tracker-Id": this.trackerId,
		};
	}

	public getTrackerIdHeaders(): HeadersInit {
		return {
			"Tracker-Id": this.trackerId,
		};
	}

	async getEndpointUrl(): Promise<string> {
		this.config.endpointUrl = await getConfigurationOption(ConfigPropertyKey.EndpointUrl);

		return this.config.endpointUrl + this.config.subdomain;
	}
}
