import { QuestionnaireActionTypes } from "../../components/questionnaire/actions/types";
import { DefaultLocalDataService } from "../../../services/localData";
import { EnabledFeaturesService } from "../../../services/features";
import { getAuthUser } from "../../../helpers/AuthenticationHelpers";
import { PortalAuthenticationMode } from "../../../models/portal";
import { isAttachmentSizeCorrect } from "../../../helpers/FileHelper";
import { rgLog } from "../../../services/log";
import { AttachmentActionTypes } from "../../components/attachment/actions/types";
import type { State } from "../../model";
import type {
	AddAttachmentAction,
	EditAttachmentAction,
	RemoveAttachmentAction,
} from "../../components/attachment";
import type {
	SubmitAction,
	CancelQuestionnaire,
} from "../../components/questionnaire/actions/definitions";
import type { Middleware } from "redux";
import type { QuestionnaireTemplate } from "../../../models/questionnaire";

const applicableActions = [
	QuestionnaireActionTypes.Create,
	QuestionnaireActionTypes.CreateActionRecord,
	QuestionnaireActionTypes.CreateNote,
	QuestionnaireActionTypes.CreateSubModuleRecord,
	QuestionnaireActionTypes.DeleteActionRecord,
	QuestionnaireActionTypes.DeleteNote,
	QuestionnaireActionTypes.DeleteSubModuleRecord,
	QuestionnaireActionTypes.UpdateBranchCondition,
	QuestionnaireActionTypes.UpdateComment,
	QuestionnaireActionTypes.UpdateField,
	QuestionnaireActionTypes.UpdateFieldProperty,
	QuestionnaireActionTypes.UpdateNotApplicable,
	QuestionnaireActionTypes.EditNote,
	QuestionnaireActionTypes.EditSubModuleRecord,
	QuestionnaireActionTypes.BranchingTriggered,
	QuestionnaireActionTypes.BranchingInitialised,
	QuestionnaireActionTypes.Submit,
	QuestionnaireActionTypes.Cancel,
	QuestionnaireActionTypes.UpdateProgress,
	AttachmentActionTypes.Add,
	AttachmentActionTypes.Remove,
	AttachmentActionTypes.Edit,
];

// // actions executed automatically in quick succession
const throttleExcludedActions = [
	QuestionnaireActionTypes.CreateSubModuleRecord,
	QuestionnaireActionTypes.EditSubModuleRecord,
	QuestionnaireActionTypes.UpdateProgress,
	AttachmentActionTypes.Add,
	AttachmentActionTypes.Edit,
	AttachmentActionTypes.Remove,
	QuestionnaireActionTypes.Cancel,
	QuestionnaireActionTypes.Submit,
	QuestionnaireActionTypes.UpdateFieldProperty,
];

const attachmentActions = [
	AttachmentActionTypes.Add,
	AttachmentActionTypes.Remove,
	AttachmentActionTypes.Edit,
];

let compareDate = Date.now();

export const persistStateMiddleware: Middleware<unknown, State> =
	(store) => (next) => async (action) => {
		const isAttachmentAction = attachmentActions.includes(action.type);
		let result;
		if (!isAttachmentAction) {
			result = next(action);
		}

		const featuresService = new EnabledFeaturesService();
		const state = store.getState();
		const localDataService = new DefaultLocalDataService();
		const current: number = Date.now();
		const throttleTime = 250;
		const isActionApplicable = applicableActions.includes(action.type);
		const isThrottleExcluded = throttleExcludedActions.includes(action.type);

		// throttle the updates to prevent to much load on indexeddb
		if (
			isActionApplicable &&
			(isThrottleExcluded || current - compareDate > throttleTime) &&
			featuresService.isOfflineEnabled()
		) {
			compareDate = Date.now();

			let user = null;
			if (
				store.getState().portal.portals[0] &&
				store.getState().portal.portals[0].authenticationMode !==
					PortalAuthenticationMode.Public
			) {
				try {
					user = await getAuthUser();
				} catch {
					return;
				}
			}

			switch (action.type) {
				case QuestionnaireActionTypes.Create:
				case QuestionnaireActionTypes.CreateActionRecord:
				case QuestionnaireActionTypes.CreateNote:
				case QuestionnaireActionTypes.CreateSubModuleRecord:
				case QuestionnaireActionTypes.DeleteActionRecord:
				case QuestionnaireActionTypes.DeleteNote:
				case QuestionnaireActionTypes.DeleteSubModuleRecord:
				case QuestionnaireActionTypes.UpdateBranchCondition:
				case QuestionnaireActionTypes.UpdateComment:
				case QuestionnaireActionTypes.UpdateField:
				case QuestionnaireActionTypes.UpdateFieldProperty:
				case QuestionnaireActionTypes.UpdateNotApplicable:
				case QuestionnaireActionTypes.EditNote:
				case QuestionnaireActionTypes.EditSubModuleRecord:
				case QuestionnaireActionTypes.BranchingTriggered:
				case QuestionnaireActionTypes.BranchingInitialised:
				case QuestionnaireActionTypes.UpdateProgress:
					localDataService.saveQuestionnaire(
						state.questionnaire as QuestionnaireTemplate,
						user,
					);
					break;
				case QuestionnaireActionTypes.Submit: {
					const submitAction = action as SubmitAction;
					if (submitAction.isSubmitSuccess === true) {
						localDataService.deleteQuestionnaire(
							(state.questionnaire as QuestionnaireTemplate).questionnaire.id,
						);
					}
					break;
				}
				case QuestionnaireActionTypes.Cancel: {
					const cancelAction = action as CancelQuestionnaire;
					if (!cancelAction.saveQuestionnaire && cancelAction.questionnaireId) {
						localDataService.deleteQuestionnaire(cancelAction.questionnaireId);
					}
					break;
				}
				case AttachmentActionTypes.Add: {
					const attachments = (action as AddAttachmentAction).attachments;
					const attachmentsAdded = await localDataService.addAttachments(
						attachments,
						user,
					);

					// Validate attachments can be loaded
					let attachmentSizeValid = true;

					for (const attachment of attachments) {
						const loadedAttachment = await localDataService.loadAttachment(
							attachment.id,
							user,
						);
						if (!(await isAttachmentSizeCorrect(loadedAttachment))) {
							rgLog("send", {
								error: new Error("Attachment did not save correctly in store"),
								customData: { fileSize: attachment.file.size },
							});
							attachmentSizeValid = false;
							break;
						}
					}

					if (!attachmentSizeValid) {
						for (const attachment of attachments) {
							await localDataService.removeAttachment(attachment.id);
						}
						store.dispatch({
							type: QuestionnaireActionTypes.FileSizeError,
							error: true,
						});
						return;
					}

					if (!attachmentsAdded) {
						return;
					}
					break;
				}
				case AttachmentActionTypes.Edit: {
					const attachmentsAdded = await localDataService.addAttachments(
						(action as EditAttachmentAction).attachments,
						user,
					);
					// dispatch to hide edit attachment's description modal
					store.dispatch({
						type: AttachmentActionTypes.ListOfEdited,
						attachmentIds: [],
					});
					if (!attachmentsAdded) {
						return;
					}
					break;
				}
				case AttachmentActionTypes.Remove:
					await localDataService.removeAttachment(
						(action as RemoveAttachmentAction).attachmentId,
					);
					break;
				default:
					break;
			}
		}

		if (isAttachmentAction) {
			result = next(action);
		}

		return result;
	};
