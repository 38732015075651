import packageJson from "../../../package.json";
import { areEventMessagesEqual, isAfterSendTimeConfiguration } from "./helpers";
import type { LastSentMessage, rg4jsMessageEvent } from "./types";

// eslint-disable-next-line import/no-mutable-exports
export let lastSentMessage: LastSentMessage | null = null;

export const setLastMessage = (message: LastSentMessage) => {
	lastSentMessage = message;
};

rg4js("setVersion", packageJson.version);

/**
 * Returns true if the last sent message is different from the next message to send
 */
export function canSendEvent(
	lastMessageSent: LastSentMessage | null,
	event: rg4jsMessageEvent,
	message: any,
	dateTime: Date,
) {
	if (lastMessageSent === null) {
		return true;
	}

	const isAfterTime = isAfterSendTimeConfiguration(dateTime, lastMessageSent.dateTime);
	const areMessagesEqual = areEventMessagesEqual(lastMessageSent, { event, message });

	if (!isAfterTime && areMessagesEqual) {
		return false;
	}

	return true;
}

/**
 * Wrapped Raygun service. To fully utilize Raygun features rg4js is loaded in index.html rather than through module
 * import. Due to CRA restricting adding variables to the global scope we cannot use rg4js without disabling eslint.
 */
export const rgLog = (event: rg4jsMessageEvent, message: any) => {
	if (process.env.NODE_ENV === "development" && event !== "trackEvent") {
		console.log(message);
	}

	const dateTime = new Date();
	if (event !== "trackEvent") {
		try {
			if (!canSendEvent(lastSentMessage, event, message, dateTime)) {
				setLastMessage({
					event,
					message,
					dateTime,
				});
				return;
			}
		} catch {
			// Failed to check raygun message. the event will be sent to raygun anyway.
		}
	}
	rg4js(event, message);

	setLastMessage({
		event,
		message,
		dateTime,
	});
};

export const rgEvent = (message: string, data: object) => {
	rg4js("recordBreadcrumb", {
		message,
		metadata: data,
		level: "info",
	});
};
