import { t } from "i18next";

export enum ConfigPropertyKey {
	Authority = "authority",
	EndpointUrl = "endpoint_url",
	PkceSecret = "pkceSecret",
	MapApiKey = "mapApiKey",
	MapCopyrightsUrl = "mapCopyrightsUrl",
	MapTileUrl = "mapTileUrl",
	SatelliteCopyrightsUrl = "satelliteCopyrightsUrl",
	SatteliteTileUrl = "satelliteTileUrl",
	EnableGainsight = "enableGainsight",
	GainsightTagKey = "gainsightTagKey",
	GainsightExclude = "gainsightExclude",
}

type IConfigProperties = {
	[key in ConfigPropertyKey]: string | null | undefined;
};

let isConfigUpToDate = false;

const configProperties: IConfigProperties = {
	authority: process.env.REACT_APP_AUTHORITY_URL,
	endpoint_url: process.env.REACT_APP_API_URL,
	pkceSecret: null,
	mapApiKey: null,
	mapCopyrightsUrl: null,
	mapTileUrl: null,
	satelliteCopyrightsUrl: null,
	satelliteTileUrl: null,
	enableGainsight: null,
	gainsightTagKey: null,
	gainsightExclude: null,
};

export const getConfiguration = async () => {
	try {
		const configData = await fetch("/config.json");
		const configText = await configData.text();
		const configJson = JSON.parse(configText);

		(Object.keys(configProperties) as (keyof IConfigProperties)[]).forEach((configKey) => {
			if (!configJson[configKey]) {
				throw new Error(t("error:configMissing"));
			}

			localStorage.setItem(
				configKey,
				configProperties[configKey] ? configProperties[configKey] : configJson[configKey],
			);
		});
	} catch (error) {
		throw new Error(t("error:configErrorOccurred"));
	}
};

export const getConfigurationOption = async (key: ConfigPropertyKey) => {
	let configurationOption = localStorage.getItem(key);

	if (!configurationOption || !isConfigUpToDate) {
		try {
			await getConfiguration();
		} catch (e) {
			if (!configurationOption) {
				throw e;
			}
		}
		isConfigUpToDate = true;
		configurationOption = localStorage.getItem(key);
	}

	return configurationOption || "";
};
