import { PageMainNavItem } from "./PageMainNavItem";
import type { PageNavItemProps } from "./PageMainNavItem";
import "./PageMainNav.styles.scss";

interface Props {
	items: PageNavItemProps[];
}

export const PageMainNavGroup = ({ items }: Props) => {
	return (
		<nav className="she-main-nav">
			<ul className="she-main-nav__group">
				{items.reduce((acc: any[], item) => {
					item.show && acc.push(<PageMainNavItem {...item} key={item.to} />);

					return acc;
				}, [])}
			</ul>
		</nav>
	);
};
