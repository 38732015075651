export function switchMatchingLanguageCode(code: string) {
	switch (code) {
		case "ar-ae":
			return "ArAE";
		case "ArAE":
			return "ar-ae";
		case "cs-cz":
			return "CsCZ";
		case "CsCZ":
			return "cs-cz";
		case "da-dk":
			return "DaDK";
		case "DaDK":
			return "da-dk";
		case "de-de":
			return "DeDE";
		case "DeDE":
			return "de-de";
		case "en-us":
			return "EnUS";
		case "EnUS":
			return "en-us";
		case "en-gb":
			return "EnGB";
		case "EnGB":
			return "en-gb";
		case "es-es":
			return "EsES";
		case "EsES":
			return "es-es";
		case "es-us":
			return "EsUS";
		case "EsUS":
			return "es-us";
		case "fr-fr":
			return "FrFR";
		case "FrFR":
			return "fr-fr";
		case "hr-hr":
			return "HrHR";
		case "HrHR":
			return "hr-hr";
		case "hu-hu":
			return "HuHU";
		case "HuHU":
			return "hu-hu";
		case "it-it":
			return "ItIT";
		case "ItIT":
			return "it-it";
		case "lt-lt":
			return "LtLT";
		case "LtLT":
			return "lt-lt";
		case "nl-nl":
			return "NlNL";
		case "NlNL":
			return "nl-nl";
		case "pa-in":
			return "PaIN";
		case "PaIN":
			return "pa-in";
		case "pe-pe":
			return "XhZA";
		case "XhZA":
			return "pe-pe";
		case "pl-pl":
			return "PlPL";
		case "PlPL":
			return "pl-pl";
		case "pt-br":
			return "PtBR";
		case "PtBR":
			return "pt-br";
		case "pt-pt":
			return "PtPT";
		case "PtPT":
			return "pt-pt";
		case "ro-ro":
			return "RoRO";
		case "RoRO":
			return "ro-ro";
		case "sk-sk":
			return "SkSK";
		case "SkSK":
			return "sk-sk";
		case "sl-si":
			return "SlSI";
		case "SlSI":
			return "sl-si";
		case "sv-se":
			return "SvSE";
		case "SvSE":
			return "sv-se";
		case "zh-hans":
			return "ZhHans";
		case "zh-HANS":
			return "ZhHans";
		case "ZhHans":
			return "zh-HANS";
		case "nb-no":
			return "NbNO";
		case "NbNO":
			return "nb-no";
		case "fi-fi":
			return "FiFI";
		case "FiFI":
			return "fi-fi";
		case "fr-ca":
			return "FrCA";
		case "FrCA":
			return "fr-ca";
		case "hi-in":
			return "HiIN";
		case "HiIN":
			return "hi-in";
		case "ja-jp":
			return "JaJP";
		case "JaJP":
			return "ja-jp";
		case "ms-my":
			return "MsMY";
		case "MsMY":
			return "ms-my";
		case "ko-kr":
			return "KoKR";
		case "KoKR":
			return "ko-kr";
		case "Ta":
			return "ta";
		case "ta":
			return "Ta";
		case "ViVN":
			return "vi-vn";
		case "vi-vn":
			return "ViVN";
		case "RuRU":
			return "ru-ru";
		case "ru-ru":
			return "RuRU";
	}
}
